import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import LocaleProvider from '@src/components/common/LocaleProvider'
import TopLevelRoutes from './Routes'
import AlertBannerOnDeprecatedStatus from './components/AlertBannerOnDeprecatedStatus'
import ThemeProvider from './ThemeProvider'
import UserProvider from './UserProvider'
import AlertBannerOnProduction from './components/AlertBannerOnProduction'

const App = () => {
  return (
    <UserProvider>
      <ThemeProvider>
        <LocaleProvider>
          <Router>
            <AlertBannerOnProduction />
            <AlertBannerOnDeprecatedStatus />
            <TopLevelRoutes />
          </Router>
        </LocaleProvider>
      </ThemeProvider>
    </UserProvider>
  )
}

export default App
