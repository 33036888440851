import type { TextFieldProps } from '@mui/material'
import { TextField } from '@mui/material'
import type { Control, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'

export type ControlledTextFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = {
  control: Control<TFieldValues>
  name: TName
  disabled?: boolean
} & Omit<TextFieldProps, keyof ControllerRenderProps<TFieldValues, TName> | 'error'>

const ControlledTextField = <TName extends FieldPath<TFieldValues>, TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  helperText,
  inputProps,
  disabled: pDisabled,
  ...props
}: ControlledTextFieldProps<TFieldValues, TName>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, disabled, ...field }, fieldState: { invalid, error } }) => (
        <TextField
          {...props}
          {...field}
          error={invalid}
          disabled={pDisabled || disabled}
          helperText={error?.message ?? helperText}
          inputProps={{ ...inputProps, formNoValidate: true }}
          value={value ?? ''} // null to empty string
          onChange={(e) => onChange(e.target.value || null)} // reconvert empty string to null
        />
      )}
    />
  )
}

export default ControlledTextField
