import { InputAdornment, Stack } from '@mui/material'
import InputTextField from '@src/components/InputTextField'
import { EProductsInsurance } from '@src/types/Constants'
import { ProductsWorksheet } from '@src/types/WorksheetSchema'
import { UseFormRegister, FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  register: UseFormRegister<ProductsWorksheet>
  insurance: EProductsInsurance
  errors: FieldErrors<ProductsWorksheet>
}

const ProductsInsuranceDetails = ({ insurance: name, register, errors }: Props) => {
  const { t } = useTranslation()
  return (
    <Stack spacing={2}>
      <InputTextField
        label={t('productsWorksheet.policy')}
        fullWidth
        {...register(`${name}.police`)}
        error={errors[name]?.police}
      />
      <InputTextField
        label={t('productsWorksheet.term')}
        error={errors[name]?.term}
        {...register(`${name}.term`)}
        InputProps={{
          endAdornment: <InputAdornment position="end">{t('common.month')}</InputAdornment>,
        }}
      />
      <InputTextField
        label={t('productsWorksheet.amount')}
        error={errors[name]?.amount}
        {...register(`${name}.amount`)}
        InputProps={{
          endAdornment: <InputAdornment position="end">$</InputAdornment>,
        }}
      />
    </Stack>
  )
}

export default ProductsInsuranceDetails
