import { formatUtcIsoDateTime } from '@src/services/Formatter'
import {
  EApplicantType,
  ECompany,
  ECreditApplicationStatus,
  EDocType,
  EDocumentStatus,
  EFinancingProgram,
  EServiceCategory,
  FeatureSwitchInfo,
} from '@src/types/Constants'
import { CreditApplication, DraftCreditApplicationDto } from '@src/types/CreditApplicationSchema'
import { RequiredDocument } from '@src/types/RequiredDocument'
import {
  MutationFunction,
  QueryFunction,
  QueryFunctionContext,
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { addDays, parseISO } from 'date-fns'

import { serializeParameter } from '../../../services/query-string'
import { CreditDashboardEntry } from '../../../types/CreditDashboardEntry'
import { getApiClient } from '../api-client'

import {
  BankAccountRequestDto,
  CreditApplicationParams,
  CreditDashboardFilters,
  UploadFilesDto,
  UploadFilesResultDto,
} from '../type'
import { transformCreditApplicationFromApi, transformCreditApplicationToApi } from './credit-transform'
import { uploadRequiredDocument } from './files-api'

const DASHBOARD_SCOPE = 'dashboard-entries'
const APP_SCOPE = 'credit-applications'
const SUMMARY = 'funding-summary'
const LIST = 'list'
const DETAIL = 'detail'
const SKIPBANKREQUEST = 'skip-bank-request-requirements'
const FEATURESWITCH = 'feature-switches'

const fiveMinutes = 300000
const oneMinute = 60000

const keysFactory = {
  allApps: () => [{ scope: APP_SCOPE }] as const,
  allDashboard: () => [{ scope: DASHBOARD_SCOPE, entity: LIST }] as const,
  dashboardSearch: (filters: CreditDashboardFilters) => [{ scope: DASHBOARD_SCOPE, entity: LIST, ...filters }] as const,
  allAppDetails: () => [{ scope: APP_SCOPE, entity: DETAIL }] as const,
  appDetail: (id: string, financingProgramId: string) =>
    [{ scope: APP_SCOPE, entity: DETAIL, id, financingProgramId }] as const,
  allSummary: () => [{ scope: SUMMARY, entity: DETAIL }] as const,
  summary: (id: string, financingProgramId: string) =>
    [{ scope: SUMMARY, entity: DETAIL, id, financingProgramId }] as const,
  allSkipBankRequestRequirements: () => [{ scope: SKIPBANKREQUEST, entity: DETAIL }] as const,
  skipBankAccountRequestRequirements: (id: string, financingProgramId: string) =>
    [{ scope: SKIPBANKREQUEST, entity: DETAIL, id, financingProgramId }] as const,
  allFeatureSwitches: () => [{ scope: FEATURESWITCH, entity: LIST }] as const,
}

export function transformDateFilterToApi(filters: CreditDashboardFilters) {
  const newFilters = { ...filters }
  newFilters.startDate = formatUtcIsoDateTime(parseISO(filters.startDate))
  newFilters.endDate = formatUtcIsoDateTime(addDays(parseISO(filters.endDate), 1))
  return newFilters
}

const getCreditApplicationList = async ({
  queryKey: [filters],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['dashboardSearch']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<CreditDashboardEntry[]>('/creditApplication', {
    params: transformDateFilterToApi(filters),
    paramsSerializer(params) {
      return serializeParameter(params)
    },
  })
  return response.data
}

export function useCreditApplicationList(filter: CreditDashboardFilters): [CreditDashboardEntry[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: [...keysFactory.dashboardSearch(filter)],
    queryFn: getCreditApplicationList,
    placeholderData: [],
    staleTime: oneMinute,
    gcTime: oneMinute,
    refetchOnWindowFocus: 'always',
  })

  return [data ?? [], isFetching]
}

const saveCreditApplication: MutationFunction<CreditApplication, Partial<DraftCreditApplicationDto>> = async (
  creditApplication,
) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<CreditApplication>(
    `/creditApplication/${encodeURIComponent(creditApplication.financingProgramId!)}`,
    transformCreditApplicationToApi(creditApplication),
  )
  return response.data
}

export function useSaveCreditApplicationDraft(): [
  MutationFunction<CreditApplication, Partial<DraftCreditApplicationDto>>,
  boolean,
  boolean,
  () => void,
  AxiosError | null,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, reset, error } = useMutation({
    mutationFn: saveCreditApplication,
    onSuccess: async (data) => {
      queryClient.setQueryData<CreditApplication>(keysFactory.appDetail(data.id, data.financingProgramId), data)
      await queryClient.invalidateQueries({ queryKey: keysFactory.allDashboard() })
      return data
    },
  })

  return [mutateAsync, isPending, isError, reset, error as AxiosError]
}

const editCreditApplication: MutationFunction<CreditApplication, Partial<DraftCreditApplicationDto>> = async (
  creditApplication,
) => {
  const apiClient = getApiClient()
  const transformedCreditApplication = transformCreditApplicationToApi(creditApplication)

  const response = await apiClient.put<CreditApplication>(
    `/creditApplication/${encodeURIComponent(transformedCreditApplication.financingProgramId!)}/${encodeURIComponent(
      transformedCreditApplication.id!,
    )}`,
    transformedCreditApplication,
  )
  response.data = transformCreditApplicationFromApi(response.data)
  return response.data
}

export function useEditCreditApplication(): [
  MutationFunction<CreditApplication, Partial<DraftCreditApplicationDto>>,
  boolean,
  boolean,
  () => void,
  AxiosError | null,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, reset, error } = useMutation({
    mutationFn: editCreditApplication,
    onSuccess: async (newData) => {
      queryClient.setQueryData<CreditApplication>(
        keysFactory.appDetail(newData.id, newData.financingProgramId),
        (oldData) => {
          // We know that if the CVT numbers are different,
          // the existing signed CVT will be refused anyways
          // but at this point we don't know yet if the workflow has finished processing
          // refusing the signed CVT here immetiately will give a visual feedback to the merchand
          // that the contract needs to be signed again
          if (oldData?.contract.cvtNumber !== newData.contract.cvtNumber) {
            const updatedData = { ...newData }

            if (updatedData.requiredDocuments && Array.isArray(updatedData.requiredDocuments)) {
              updatedData.requiredDocuments = updatedData.requiredDocuments.map((doc) => {
                if (doc.typeId === EDocType.signedCvt) {
                  return { ...doc, status: EDocumentStatus.Refused }
                }
                return doc
              })
            }

            return { ...oldData, ...updatedData }
          }

          return { ...oldData, ...newData }
        },
      )
      if (newData.status !== ECreditApplicationStatus.Draft)
        await queryClient.invalidateQueries({ queryKey: keysFactory.allDashboard() })
    },
  })

  return [mutateAsync, isPending, isError, reset, error as AxiosError]
}

const getCreditApplicationById = async ({
  queryKey: [{ id, financingProgramId }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['appDetail']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get(`/CreditApplication/${financingProgramId}/${encodeURIComponent(id)}`)
  const creditAppResponse = transformCreditApplicationFromApi(response.data as CreditApplication)

  return creditAppResponse
}

export function useCreditApplicationById(
  creditApplicationParams: CreditApplicationParams,
  shouldPoll: boolean,
): [CreditApplication, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.appDetail(
      creditApplicationParams.creditApplicationId,
      creditApplicationParams.financingProgramId,
    ),
    queryFn: getCreditApplicationById,
    enabled: !!creditApplicationParams.creditApplicationId,
    staleTime: fiveMinutes,
    gcTime: fiveMinutes,
    refetchInterval: shouldPoll ? 5000 : undefined,
  })

  return [data!, isFetching]
}

export function useUploadRequiredDocument(
  creditApplicationParams: CreditApplicationParams,
): [MutationFunction<UploadFilesResultDto, UploadFilesDto>, boolean, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, reset } = useMutation({
    mutationFn: uploadRequiredDocument,
    onSuccess: async (response) => {
      const updatedCreditApp = {
        ...queryClient.getQueryData(
          keysFactory.appDetail(response.creditApplicationId, creditApplicationParams.financingProgramId),
        ),
      } as CreditApplication
      const updatedFileIndex = updatedCreditApp.requiredDocuments.findIndex(
        (x) =>
          x.applicantType === response.applicantType && x.typeId === response.typeId && x.subKey === response.subKey,
      )
      if (updatedFileIndex > -1) {
        updatedCreditApp.requiredDocuments = [...updatedCreditApp.requiredDocuments]
        updatedCreditApp.requiredDocuments[updatedFileIndex] = {
          ...updatedCreditApp.requiredDocuments[updatedFileIndex],
          status: response.status,
          receivedOn: response.receivedOn,
        }
        await queryClient.setQueryData(
          keysFactory.appDetail(response.creditApplicationId, creditApplicationParams.financingProgramId),
          updatedCreditApp,
        )
      }
    },
  })
  return [mutateAsync, isPending, isError, reset]
}

const deleteCoapplicant: MutationFunction<CreditApplication, CreditApplicationParams> = async ({
  creditApplicationId,
  financingProgramId,
}) => {
  const apiClient = getApiClient()
  const response = await apiClient.delete<CreditApplication>(
    `/CreditApplication/${financingProgramId}/${encodeURIComponent(creditApplicationId)}/coapplicant`,
  )
  return response.data
}

export function useDeleteCoapplicant(): [
  MutationFunction<CreditApplication, CreditApplicationParams>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: deleteCoapplicant,
    onSuccess: async (newData) => {
      queryClient.setQueryData<CreditApplication>(
        keysFactory.appDetail(newData.id, newData.financingProgramId),
        (oldData) => {
          return { ...oldData, ...newData }
        },
      )
      await queryClient.invalidateQueries({ queryKey: keysFactory.allDashboard() })
    },
  })

  return [mutateAsync, isPending, reset]
}

const qualify: MutationFunction<CreditApplication, CreditApplicationParams> = async ({
  creditApplicationId,
  financingProgramId,
}) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<CreditApplication>(
    `/CreditApplication/${financingProgramId}/${encodeURIComponent(creditApplicationId)}/qualify`,
  )
  return response.data
}

export function useQualify(): [
  UseMutateAsyncFunction<CreditApplication, Error, CreditApplicationParams, unknown>,
  boolean,
  boolean,
  boolean,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationFn: qualify,
    onSuccess: async (updatedData) => {
      queryClient.setQueryData(
        keysFactory.appDetail(updatedData.id, updatedData.financingProgramId),
        (existingData: CreditApplication) => {
          // Cherry pick data because BE returns an incomplete filtered application
          return {
            ...existingData,
            consentHardHit: updatedData.consentHardHit,
            versionTag: updatedData.versionTag,
            updatedOn: updatedData.updatedOn,
          }
        },
      )
      await queryClient.invalidateQueries({ queryKey: keysFactory.allDashboard() })
    },
  })
  return [mutateAsync, isPending, isError, isSuccess]
}

const postStatus: MutationFunction<
  CreditApplication,
  { id: string; status: string; financingProgramId: string }
> = async ({ id, status, financingProgramId }) => {
  const apiClient = getApiClient()
  const response = await apiClient.put(
    `/CreditApplication/${financingProgramId}/${encodeURIComponent(id)}/status/${encodeURIComponent(status)}`,
  )

  return response.data as CreditApplication
}

export function usePostStatus(): [
  MutationFunction<CreditApplication, { id: string; status: string; financingProgramId: string }>,
  boolean,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess, reset } = useMutation({
    mutationFn: postStatus,
    onSuccess: async (updatedData) => {
      queryClient.setQueryData(
        keysFactory.appDetail(updatedData.id, updatedData.financingProgramId),
        (existingData: CreditApplication) => {
          // Cherry pick data because BE returns an incomplete filtered application
          return {
            ...existingData,
            status: updatedData.status,
            versionTag: updatedData.versionTag,
            updatedOn: updatedData.updatedOn,
          }
        },
      )
      await queryClient.invalidateQueries({ queryKey: keysFactory.allDashboard() })
    },
  })

  return [mutateAsync, isPending, isError, isSuccess, reset]
}

const skipBankAccountRequest: MutationFunction<CreditApplication, BankAccountRequestDto> = async (
  bankAccountRequestDto: BankAccountRequestDto,
) => {
  const apiClient = getApiClient()
  const response = await apiClient.post(
    `/CreditApplication/${bankAccountRequestDto.creditApplicationParams.financingProgramId}/${bankAccountRequestDto.creditApplicationParams.creditApplicationId}/SkipBankAccountRequest`,
    bankAccountRequestDto,
  )

  return response.data as CreditApplication
}

export function useSkipBankAccountRequest(): [
  MutationFunction<CreditApplication, BankAccountRequestDto>,
  boolean,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()

  const { mutateAsync, isPending, isError, isSuccess, reset } = useMutation({
    mutationFn: skipBankAccountRequest,
    onSuccess: (updatedData) => {
      queryClient.setQueryData(keysFactory.appDetail(updatedData.id, updatedData.financingProgramId), updatedData)
    },
  })

  return [mutateAsync, isPending, isError, isSuccess, reset]
}

const createBankAccountRequestAndSendUrl: MutationFunction<CreditApplication, BankAccountRequestDto> = async (
  bankAccountRequestDto: BankAccountRequestDto,
) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<CreditApplication>(
    `/CreditApplication/${bankAccountRequestDto.creditApplicationParams.financingProgramId}/${bankAccountRequestDto.creditApplicationParams.creditApplicationId}/CreateBankAccountRequest`,
    bankAccountRequestDto,
  )

  return response.data
}

export function useCreateBankAccountRequestAndSendUrl(): [
  MutationFunction<CreditApplication, BankAccountRequestDto>,
  boolean,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()

  const { mutateAsync, isPending, isError, isSuccess, reset } = useMutation({
    mutationFn: createBankAccountRequestAndSendUrl,
    onSuccess: (updatedData) => {
      queryClient.setQueryData(keysFactory.appDetail(updatedData.id, updatedData.financingProgramId), updatedData)
    },
  })

  return [mutateAsync, isPending, isError, isSuccess, reset]
}

export type FundingSummaryDto = {
  financedAmount: string
  activationOn: string
  merchantId: string
  merchantPaymentMethod: string
  merchantName: string
  merchantAddress: string
  merchantPhone: string
  promotion: string
  merchantFee: string
  customerAccountNumber: string
  customerNameProvince: string
  customerService: EServiceCategory
  financingProgramId: EFinancingProgram
  financingCompanyId: ECompany
}

const getFundingSummaryById: QueryFunction<
  FundingSummaryDto,
  ReturnType<(typeof keysFactory)['summary']>,
  [string]
> = async ({ queryKey: [{ id, financingProgramId }] }) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<FundingSummaryDto>(
    `/CreditApplication/${encodeURIComponent(financingProgramId)}/${encodeURIComponent(id)}/funding-summary`,
  )
  return response.data
}

export function useFundingSummaryById(
  creditApplicationId: string,
  financingProgramId: EFinancingProgram,
): [FundingSummaryDto | undefined, boolean] {
  const { data, isFetching } = useQuery({
    queryKey: keysFactory.summary(creditApplicationId, financingProgramId),
    queryFn: getFundingSummaryById,
    enabled: !!creditApplicationId,
    staleTime: fiveMinutes,
    gcTime: fiveMinutes,
  })

  return [data, isFetching]
}

const continueWithComputedIncome: MutationFunction<
  CreditApplication,
  { creditApplicationParams: CreditApplicationParams; applicantType: EApplicantType }
> = async ({ creditApplicationParams, applicantType }) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<CreditApplication>(
    `/CreditApplication/${creditApplicationParams.financingProgramId}/${creditApplicationParams.creditApplicationId}/ContinueWithComputedIncome/${applicantType}`,
  )

  return response.data
}

export function useContinueWithComputedIncome(): [
  typeof continueWithComputedIncome,
  boolean,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess, reset } = useMutation({
    mutationFn: continueWithComputedIncome,
    onSuccess: (updatedData) => {
      queryClient.setQueryData(keysFactory.appDetail(updatedData.id, updatedData.financingProgramId), updatedData)
    },
  })

  return [mutateAsync, isPending, isError, isSuccess, reset]
}

const resetBankAccountRequest: MutationFunction<CreditApplication, BankAccountRequestDto> = async (
  bankAccountRequestDto: BankAccountRequestDto,
) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<CreditApplication>(
    `/CreditApplication/${bankAccountRequestDto.creditApplicationParams.financingProgramId}/${bankAccountRequestDto.creditApplicationParams.creditApplicationId}/ResetBankAccount`,
    bankAccountRequestDto,
  )

  return response.data
}

export function useResetBankAccountRequest(): [typeof resetBankAccountRequest, boolean, boolean, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess, reset } = useMutation({
    mutationFn: resetBankAccountRequest,
    onSuccess: (updatedData) => {
      queryClient.setQueryData(keysFactory.appDetail(updatedData.id, updatedData.financingProgramId), updatedData)
    },
  })

  return [mutateAsync, isPending, isError, isSuccess, reset]
}

const skipBankAccountRequestRequirements = async ({
  queryKey: [{ id, financingProgramId }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['skipBankAccountRequestRequirements']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<RequiredDocument[]>(
    `/CreditApplication/${financingProgramId}/${encodeURIComponent(id)}/SkipBankRequestRequirements`,
  )
  return response.data
}

export function useSkipBankAccountRequestRequirements(
  creditApplicationParams: CreditApplicationParams,
): [RequiredDocument[], boolean] {
  const { data, isFetching } = useQuery({
    queryKey: keysFactory.skipBankAccountRequestRequirements(
      creditApplicationParams.creditApplicationId,
      creditApplicationParams.financingProgramId,
    ),
    queryFn: skipBankAccountRequestRequirements,
    enabled: !!creditApplicationParams.creditApplicationId,
    staleTime: fiveMinutes,
    gcTime: fiveMinutes,
  })

  return [data!, isFetching]
}

const getFeatureSwitchList = async () => {
  const apiClient = getApiClient()
  const response = await apiClient.get<FeatureSwitchInfo>('/FeatureSwitch')
  return response.data
}

export function useFeatureSwitchList(): [FeatureSwitchInfo | undefined, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.allFeatureSwitches(),
    queryFn: getFeatureSwitchList,
    staleTime: fiveMinutes,
    gcTime: fiveMinutes,
  })

  return [data!, isFetching]
}
