import { Typography } from '@mui/material'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'
import CreditAlertMessage from './CreditAlertMessage'

type Props = {
  creditApp: CreditApplication
}

const RequiresIntervention = ({ creditApp }: Props) => {
  const { t } = useTranslation()

  const applicantAlerts = creditApp.applicant.creditAlerts
  const coapplicantAlerts = creditApp.coApplicant?.creditAlerts

  if (!applicantAlerts && !coapplicantAlerts) {
    return null
  }

  return (
    <>
      <CreditAlertMessage
        applicantAlerts={applicantAlerts}
        coapplicantAlerts={coapplicantAlerts}
        warningId="hasNameMismatch"
        warningMessage={t('viewCreditApplication.nameMismatch')}
      />

      {applicantAlerts?.hasPreviousApplication && (
        <Typography variant="body1">{t('viewCreditApplication.previousApp')}</Typography>
      )}

      <CreditAlertMessage
        applicantAlerts={applicantAlerts}
        coapplicantAlerts={coapplicantAlerts}
        warningId="hasCreditLocked"
        warningMessage={t('viewCreditApplication.creditLocked')}
      />

      <CreditAlertMessage
        applicantAlerts={applicantAlerts}
        coapplicantAlerts={coapplicantAlerts}
        warningId="hasInvalidCity"
        warningMessage={t('viewCreditApplication.invalidCity')}
      />

      <CreditAlertMessage
        applicantAlerts={applicantAlerts}
        coapplicantAlerts={coapplicantAlerts}
        warningId="hasGeneralCreditReportError"
        warningMessage={t('viewCreditApplication.generalCreditReportError')}
      />

      <CreditAlertMessage
        applicantAlerts={applicantAlerts}
        coapplicantAlerts={coapplicantAlerts}
        warningId="isBanned"
        warningMessage={t('viewCreditApplication.bannedApplicant')}
      />
    </>
  )
}

export default RequiresIntervention
