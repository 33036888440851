import { ProvinceConfigs } from '@src/data/api/worksheet-api/configProvinces-api'
import { programConfigByFinancingProgramId } from '@src/data/creditapp-selectors'
import i18n from '@src/services/i18n'
import { array, bool, InferType, mixed, number, object, string } from 'yup'
import { AddressSchema, BaseAddressSchema } from './AddressSchema'
import yupExtInt from './common/SchemaTypes'
import yup from './common/yup-extended'
import {
  EBeneficiaryType,
  ECreditApplicationStatus,
  EFinancingProgram,
  EGender,
  EHomeStatus,
  EHomeStatusList,
  EJobType,
  ELanguage,
  ELanguageList,
  EOriginSystemId,
  EOtherIncomeType,
  EPaymentPlan,
  EServiceCategory,
} from './Constants'
import { Contract } from './Contract'
import { CreditDecision, NormCreditDecision } from './CreditDecision'
import { ElectronicSignature } from './ElectronicSignature'
import { FilteredWorksheet } from './FilteredWorksheet'
import { PrequalificationDecision } from './PrequalificationDecision'
import { RequiredDocument } from './RequiredDocument'

export const expensesSchema = object({
  financingProgramId: mixed<EFinancingProgram>()
    .nullable()
    .default('' as EFinancingProgram),

  monthlyInstallmentLoanPayment: yupExtInt.integer.min(0).nullable(),
  monthlyStudentLoanPayment: yupExtInt.integer.min(0).nullable(),
  monthlyLineOfCreditPayment: yupExtInt.integer.min(0).nullable(),
  monthlyCreditCardsPayment: yupExtInt.integer.min(0).nullable(),
  monthlyPaydayLoanPayment: yupExtInt.integer.min(0).nullable(),
  monthlyPersonalLoanPayment: yupExtInt.integer.min(0).nullable(),
  monthlyOtherLoanPayment: yupExtInt.integer.min(0).nullable(),

  monthlyOtherExpensePayment: number().positive().nullable(),
  otherExpensePaymentDescription: string()
    .nullable()
    .max(50)
    .when('monthlyOtherExpensePayment', (val) => {
      return val && (val as unknown as number) > 0
        ? string().required('common.errors.required').default('')
        : string().notRequired()
    }),

  homeFeeTypeId: yup
    .mixed<EHomeStatus>()
    .oneOf([undefined, ...EHomeStatusList])
    .default(null)
    .nullable()
    .when('financingProgramId', {
      is: EFinancingProgram.Personal,
      then: (schema) => schema.required(),
    }),

  houseMarketValue: yupExtInt.double.min(1, 'common.errors.positive').notRequired().nullable(),

  rentMonthly: yupExtInt.double,
  mortgageMonthly: yupExtInt.double,

  housingMonthly: yupExtInt.double.when('homeFeeTypeId', {
    is: (value: EHomeStatus) => value === EHomeStatus.Renter,
    then: (schema) => schema.required('common.errors.required').min(1),
    otherwise: (schema) => schema.notRequired().positive(),
  }),

  landlord: string().nullable().notRequired().default(''),

  monthlyOwnerInsuranceFee: yupExtInt.double.min(0),
  monthlyMortgageInsuranceFee: yupExtInt.double.min(0),
  monthlyMunicipalAndSchoolTaxPayment: yupExtInt.double.min(0),
  monthlyCondominiumFee: yupExtInt.double.min(0),
  monthlyPublicServiceFee: yupExtInt.double.min(0),
  monthlyRrspRefund: yupExtInt.double.min(0),
  monthlyOtherHomeFee: yupExtInt.double.min(0),

  isCompleted: bool().default(false).nullable(),
})

export type ApplicantExpenses = InferType<typeof expensesSchema>

export const ExpensesSchema = expensesSchema.when((value: ApplicantExpenses[], schema) => {
  if (value[0]?.houseMarketValue || value[0]?.housingMonthly) {
    schema.fields.homeFeeTypeId = yup.mixed<EHomeStatus>().oneOf(EHomeStatusList).required()
  }
  return schema
})

export const ApplicantAddressSchema = AddressSchema.shape({
  years: yupExtInt.integer.min(0).notRequired().nullable(),
  months: yupExtInt.integer.when('years', (value) => {
    return value && value[0] > 0
      ? yupExtInt.integer.notRequired().positive()
      : yupExtInt.integer.required('common.errors.required').positive()
  }),
})

export const JobSchema = object({
  id: string().default(''),
  employerName: string().required('common.errors.required').default(''),
  jobTitle: string().required('common.errors.required').default(''),
  jobType: yupExtInt.numberEnum<EJobType>().required('common.errors.required').default(null),
  annualSalary: yupExtInt.integer.required('common.errors.required').min(1, 'common.errors.positive').default(null),
  employerPhone: string().IsValidCanadianPhone().nullable(),
  employerPhoneExt: string().IsValidPhoneExtension().nullable(),
  address: BaseAddressSchema.nullable(),
  years: yupExtInt.integer.min(0).notRequired().nullable(),
  months: yupExtInt.integer.when('years', (value) => {
    return value && value[0] > 0
      ? yupExtInt.integer.notRequired().positive()
      : yupExtInt.integer.required('common.errors.required').min(1)
  }),
  verificationContactName: string().nullable(),
  verificationContactRole: string().nullable(),
  verificationContactPhone: string().nullable(),
  isIncomeLocked: bool().default(false),
})
export type ApplicantJob = InferType<typeof JobSchema>

export const OtherIncomeSchema = object({
  id: string().default(''),
  typeId: yupExtInt.integer.required('common.errors.required'),
  description: string()
    .nullable()
    .when('typeId', (value) => {
      return value[0] === EOtherIncomeType.other ? string().required('common.errors.required') : string().notRequired()
    }),
  annualAmount: yupExtInt.integer.required('common.errors.required').positive(),
  isIncomeLocked: bool().default(false),
})
export type ApplicantOtherIncome = InferType<typeof OtherIncomeSchema>

export type IncomeVerification = {
  thirdPartyFlowCompleted: boolean
  incomeMismatch: boolean
  nameMismatch: boolean
  allIncomeTasksConfirmed: boolean
  hasIncomeServiceAdjustment: boolean
  hasSkippedIncomeValidation: boolean
  thirdPartyIncome: number
  declaredIncome: number
  requiredExternalStep: RequiredExternalStep
}

export type RequiredExternalStep = {
  applicantType: string
  status: string
}

const draftApplicantSchema = (provincesConfigs: ProvinceConfigs[] = []) => ({
  id: string().nullable().default(null), // le default null est nécessaire pour le createEntityAdapter
  financingProgramId: mixed<EFinancingProgram>(),
  middleName: string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(50)
    .default('')
    .nullable(),
  relationWithApplicant: string().when('isPrimaryApplicant', (value) => {
    return !value[0]
      ? string().nullable().required('common.errors.required').default('')
      : string().notRequired().nullable().default('')
  }),
  firstName: string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(50)
    .required('common.errors.required')
    .default(''),
  lastName: string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(50)
    .required('common.errors.required')
    .default(''),
  birthDate: string()
    .default('')
    .isValidDate()
    .validateAgeRequirement('common.errors.ageRequirement', provincesConfigs),
  sin: string().SinType().default(''),
  languageId: yupExtInt
    .numberEnum<ELanguage>(ELanguageList)
    .nonNullable()
    .default(() => (i18n.resolvedLanguage === 'fr' ? ELanguage.French : ELanguage.English))
    .required('common.errors.required'),
  genderId: yupExtInt.numberEnum<EGender>().nullable().default(null),
  maritalStatusId: yupExtInt.integer.nullable().default(null),
  homePhone: string().default('').IsValidCanadianPhone().nullable(),
  cellPhone: string().default('').IsValidCanadianPhone().nullable(),
  email: string().notRequired().email('common.errors.invalidEmail').max(60).default(''),
  // Incomes
  currentJobs: array(JobSchema).default([]),
  previousJobs: array(JobSchema).default([]),
  otherIncomes: array(OtherIncomeSchema).default([]),
  // address
  currentAddress: ApplicantAddressSchema,
  expenses: ExpensesSchema.notRequired().default(ExpensesSchema.getDefault()),
  isPrimaryApplicant: bool().required('common.errors.required').default(true),
})

export const DraftApplicantSchema = (provincesConfigs: ProvinceConfigs[]) =>
  yup.object(draftApplicantSchema(provincesConfigs))

const fullApplicantSchema = (provincesConfigs: ProvinceConfigs[]) => {
  const schema = { ...draftApplicantSchema(provincesConfigs) }
  schema.birthDate = schema.birthDate.required('common.errors.required').isValidDate()
  schema.genderId = schema.genderId.required('common.errors.required')
  schema.languageId = schema.languageId.required('common.errors.required')
  schema.maritalStatusId = schema.maritalStatusId.required('common.errors.required')
  schema.cellPhone = schema.cellPhone.IsValidCanadianPhone().required('common.errors.required')
  schema.email = schema.email.required('common.errors.required')
  schema.currentAddress = ApplicantAddressSchema
  return schema
}

export const FullApplicantSchema = (provincesConfigs: ProvinceConfigs[]) =>
  object(fullApplicantSchema(provincesConfigs))

export type CreditAlertsDto = {
  hasNameMismatch: boolean
  hasDoBMismatch: boolean
  hasPreviousApplication: boolean
  hasCreditLocked: boolean
  hasInvalidCity: boolean
  hasGeneralCreditReportError: boolean
  isBanned: boolean
}

export type ApplicantDto = InferType<ReturnType<typeof DraftApplicantSchema>> & {
  incomeVerification?: IncomeVerification
  hardHitReportReceivedOn?: Date
  creditAlerts?: CreditAlertsDto
}
export const buildDraftCreditApplicationSchemaObject = (provincesConfigs?: ProvinceConfigs[]) => {
  const draftCreditApplicationSchema = {
    id: string().default(''), // le default null est nécessaire pour le createEntityAdapter
    financingProgramId: mixed<EFinancingProgram>(),
    loanPurposeId: string()
      .nullable()
      .when('financingProgramId', ([financingProgramId], schema) => {
        const config = programConfigByFinancingProgramId(financingProgramId as EFinancingProgram)
        if (config?.loanPurposeIdIsRequired ?? true) {
          return schema.required('common.errors.required')
        }
        return string().notRequired().nullable()
      })
      .default(null),
    requestedLoanAmount: yupExtInt.double.when('financingProgramId', (financingProgramId, schema) => {
      const value = financingProgramId[0] as EFinancingProgram
      const config = programConfigByFinancingProgramId(value)
      if (config?.requestedLoanAmountIsRequired ?? true) {
        return schema
          .required('common.errors.required')
          .min(config.minLoanAmount, 'common.errors.greaterOrEqual_MinimumLoanAmount')
          .max(config.maxLoanAmount, 'common.errors.lowerOrEqual_MaximumLoanAmount')
      }

      return yupExtInt.double
    }),
    merchantId: string().required('common.errors.required').default(''),
    status: mixed<ECreditApplicationStatus>()
      .required('common.errors.required')
      .default(ECreditApplicationStatus.Draft),
    applicant: DraftApplicantSchema(provincesConfigs ?? []).required('common.errors.required'),
    coApplicant: DraftApplicantSchema(provincesConfigs ?? [])
      .nullable()
      .default(null),
    editLocked: bool().default(false),
    correspondanceLanguageId: number(),
    versionTag: string(),
    beneficiaryTypeId: yupExtInt.integer
      .nullable()
      .when('financingProgramId', ([financingProgramId], schema) => {
        const config = programConfigByFinancingProgramId(financingProgramId as EFinancingProgram)
        if (config?.beneficiaryIsRequired ?? true) {
          return schema
            .required('common.errors.required')
            .oneOf([
              EBeneficiaryType.NotSet,
              EBeneficiaryType.Applicant,
              EBeneficiaryType.Coapplicant,
              EBeneficiaryType.Other,
            ])
            .default(EBeneficiaryType.Applicant)
        }
        return yupExtInt.integer.nullable()
      })
      .default(null),
    otherBeneficiaryFirstName: string()
      .trim()
      .when('loanPurposeId', (loanPurposeId, schema) => {
        if (
          loanPurposeId[0] === EServiceCategory.Veterinary ||
          loanPurposeId[0] === EServiceCategory.GoodsAndServices
        ) {
          return schema.notRequired().nullable()
        }
        return schema.when('beneficiaryTypeId', (value) => {
          return value[0] === EBeneficiaryType.Other
            ? string().required('common.errors.required')
            : string().notRequired().nullable()
        })
      }),

    otherBeneficiaryLastName: string()
      .trim()
      .when('loanPurposeId', (loanPurposeId, schema) => {
        if (loanPurposeId[0] === EServiceCategory.GoodsAndServices) {
          return schema.notRequired().nullable()
        }
        return schema.when('beneficiaryTypeId', (value) => {
          return value[0] === EBeneficiaryType.Other
            ? string().required('common.errors.required')
            : string().notRequired().nullable()
        })
      }),
    originSystemId: mixed<EOriginSystemId>()
      .required('common.errors.required')
      .default(EOriginSystemId.MerchantDashboard),
    consentSoftHit: bool().required('common.errors.required').default(false),
    consentHardHit: bool().required('common.errors.required').default(false),
  }
  return draftCreditApplicationSchema
}
export const buildDraftCreditApplicationSchema = (provinceConfigs?: ProvinceConfigs[] | []) => {
  return yup
    .object(buildDraftCreditApplicationSchemaObject(provinceConfigs))
    .test('test-coappSameMobileError', 'common.errors.coappSameMobileError', function distinctCellphoneTest(value) {
      const { createError } = this
      if (value?.coApplicant !== null) {
        const coappCellPhone = value.coApplicant.cellPhone
        const appCellphone = value.applicant.cellPhone
        if (appCellphone && coappCellPhone) {
          return (
            appCellphone !== coappCellPhone ||
            createError({ path: 'coApplicant.cellPhone', message: 'common.errors.coappSameMobileError' })
          )
        }
      }
      return true
    })
}
export const DraftCreditApplicationSchema = buildDraftCreditApplicationSchema()

export const buildFullCreditApplicationSchema = (provinceConfigs?: ProvinceConfigs[]) => {
  const schema = yup.object(buildDraftCreditApplicationSchemaObject(provinceConfigs)).shape({
    merchantId: string().required('common.errors.required'),
    applicant: FullApplicantSchema(provinceConfigs ?? []).required('common.errors.required'),
    coApplicant: FullApplicantSchema(provinceConfigs ?? [])
      .nullable()
      .default(null),
  })
  return schema
}

export const FullCreditApplicationSchema = buildFullCreditApplicationSchema()

export type DraftCreditApplicationDto = InferType<typeof DraftCreditApplicationSchema>

type EditCreditApplicationDto = InferType<typeof FullCreditApplicationSchema>
export type CreditApplication = EditCreditApplicationDto & {
  applicant: ApplicantDto
  coApplicant: ApplicantDto | null
  currentRevisionId: string
  referenceNumber: number
  parentReferenceNumber: number | null
  parentCreditApplicationId: string | null
  normsCreditDecision: NormCreditDecision | null
  finalCreditDecision: CreditDecision
  createdOn: Date
  updatedOn: Date
  expiresOn: Date
  deliveryOn: string
  createdByUserFullName: string
  correspondanceLanguageId: number
  cvtNumber: number | null
  loanId: number | null
  loanPurposeId: EServiceCategory
  prequalificationDecision: PrequalificationDecision
  merchantPaymentPlanId: EPaymentPlan
  allIncomesConfirmed: boolean
  requiredDocuments: RequiredDocument[]
  loanCreated: boolean
  beneficiaryTypeId: number
  otherBeneficiaryFirstName: string
  otherBeneficiaryLastName: string
  consentSoftHit: boolean
  consentHardHit: boolean
  electronicSignatures: ElectronicSignature[]
  financingProgramId: EFinancingProgram
  worksheet: FilteredWorksheet | null
  contract: Contract
  hasTasksBeforeCvt: boolean
  availableCreditAmount: number
  hasOngoingElectronicSignature: boolean
}
