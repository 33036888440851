import { UserDtoFromJwt } from '@src/types/User'
import axios, { AxiosError } from 'axios'

export const JWT_LOCAL_STORAGE_KEY = 'merchantToken'

export function isExpiredToken(expiryDate: number): boolean {
  const date = new Date().valueOf() / 1000
  return date > expiryDate
}

export function parseJwt(token: string): object {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )

  const payload = JSON.parse(jsonPayload) as { isBannerUser?: string | boolean }

  if (payload.isBannerUser !== undefined) {
    payload.isBannerUser = payload.isBannerUser === 'True'
  }

  return payload
}

export function loadUserFromJwtString(jwtToken: string): UserDtoFromJwt | null {
  const dto = parseJwt(jwtToken) as UserDtoFromJwt
  return isExpiredToken(dto.exp) ? null : dto
}

export function getMerchantAuthToken() {
  let token = localStorage.getItem(JWT_LOCAL_STORAGE_KEY) ?? ''
  const user = token ? loadUserFromJwtString(token) : null
  if (!user) token = ''
  return token
}

function resolveApiUrl() {
  if (import.meta.env.PROD) return `${window.location.origin}/api`
  return import.meta.env.VITE_API_URL
}

const jwtToken = getMerchantAuthToken()
let instance = axios.create({
  baseURL: resolveApiUrl(),
  headers: {
    Authorization: `Bearer ${jwtToken}`,
    'X-requestor': `MerchantsDashboardApp:${import.meta.env.VITE_VERSION}`,
  },
})

export function initMerchantApiClient(reportApiError: (error: AxiosError) => void) {
  const cJwtToken = getMerchantAuthToken()
  instance = axios.create({
    baseURL: resolveApiUrl(),
    headers: {
      Authorization: `Bearer ${cJwtToken}`,
      'X-requestor': `MerchantsDashboardApp:${import.meta.env.VITE_VERSION}`,
    },
  })

  instance.interceptors.response.use(
    (respons) => {
      return respons
    },
    (error: AxiosError) => {
      if (reportApiError) reportApiError(error)
      throw error
    },
  )
}

export function getApiClient() {
  return instance
}
