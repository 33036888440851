import { Grid, MenuItem } from '@mui/material'
import ControlledSelect from '@src/components/common/ControlledSelect'
import ControlledTextField from '@src/components/common/ControlledTextfield'
import { DatePicker } from '@src/components/DatePicker'
import SelectComponent from '@src/components/SelectComponent'
import { useComputeFirstPaymentDateOptions } from '@src/containers/ViewCreditApplicationPage/worksheet-hooks'
import { programConfigByFinancingProgramId } from '@src/data/creditapp-selectors'
import { cantSelectDate, isVariableInterest } from '@src/data/worksheet-selectors'
import { formatDate } from '@src/services/Formatter'
import { EPaymentPlan } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import { EditPersonalLoanWorksheetDto } from '@src/types/WorksheetSchema'
import { useCallback, useMemo } from 'react'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  listHolidays: Date[]
  creditApplication: CreditApplication
  merchantPayments: { label: string; value: string; id: string }[]
  disabledAmountInput?: boolean
  maxTermDuration?: number
  loanTermChoicesList: Array<SelectValueListItem>
  isLoadingTerm: boolean
  allowWeekendOrHolidayActivationDate: boolean
}

const WorksheetDetails = ({
  creditApplication,
  listHolidays,
  merchantPayments,
  disabledAmountInput,
  loanTermChoicesList,
  isLoadingTerm,
  allowWeekendOrHolidayActivationDate,
}: Props) => {
  const { t } = useTranslation()
  const { control, register } = useFormContext<EditPersonalLoanWorksheetDto>()
  const { errors } = useFormState({ control })
  const [deliveryOn, paymentPlanId] = useWatch({ control, name: ['deliveryOn', 'paymentPlanId'] })
  const firstPaymentDateOptions = useComputeFirstPaymentDateOptions(deliveryOn, listHolidays)
  const shouldDisableDate = useCallback(
    (date: Date) => {
      if (allowWeekendOrHolidayActivationDate) {
        return false
      }
      return cantSelectDate(date, listHolidays)
    },
    [listHolidays, allowWeekendOrHolidayActivationDate],
  )

  const hasVariableInterest = isVariableInterest(paymentPlanId)
  const programConfig = programConfigByFinancingProgramId(creditApplication.financingProgramId)
  const availableFrequencyPayments = programConfig.frequencyPayments

  const termOptions = useMemo(
    () =>
      loanTermChoicesList.map((loanTerm) => (
        <MenuItem key={loanTerm.value} value={loanTerm.value}>
          {loanTerm.label}
        </MenuItem>
      )),
    [loanTermChoicesList],
  )

  const paymentFrequencyOptions = useMemo(
    () =>
      availableFrequencyPayments.map((frequency) => (
        <MenuItem key={frequency} value={frequency}>
          {t(`frequencyPayment.${frequency}`)}
        </MenuItem>
      )),
    [availableFrequencyPayments, t],
  )
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={4}>
        <ControlledTextField
          label={t('worksheet.requestedAmount')}
          control={control}
          name="amountRequested"
          fullWidth
          disabled={disabledAmountInput}
          required
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DatePicker
          name="deliveryOn"
          control={control}
          error={errors?.deliveryOn}
          label={t('worksheet.activationDate')}
          disablePast
          minDate={new Date()}
          shouldDisableDate={shouldDisableDate}
          maxDate={creditApplication?.expiresOn}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ControlledSelect
          control={control}
          name="term"
          label={t('worksheet.loanTerm')}
          options={termOptions}
          disabled={isLoadingTerm || hasVariableInterest}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ControlledSelect
          control={control}
          name="paymentFrequency"
          label={t('worksheet.paymentFrequency') as string}
          options={paymentFrequencyOptions}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          valueList={firstPaymentDateOptions?.map((item) => ({
            label: formatDate(item),
            value: formatDate(item),
          }))}
          label={t('worksheet.firstPaymentOn') as string}
          {...register('firstPaymentOn')}
          error={errors?.firstPaymentOn}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          valueList={merchantPayments?.map((item) => ({
            label: item.label.includes(EPaymentPlan.regularDailyInterests)
              ? t(`ePaymentPlan.${item.label as EPaymentPlan}`)
              : item.label,
            value: item.value,
          }))}
          label={t('worksheet.paymentPlan') as string}
          {...register('paymentPlanId')}
          error={errors?.paymentPlanId}
        />
      </Grid>
    </Grid>
  )
}

export default WorksheetDetails
