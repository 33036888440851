import {
  Button,
  ButtonProps,
  Collapse,
  IconButton,
  IconButtonProps,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import { EFinancingProgram } from '@src/types/Constants'
import { memo, useId, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Add as AddIcon, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

type Props = { supportedFinancingPrograms: EFinancingProgram[] }

export const AddApplicationListItem = memo(({ supportedFinancingPrograms }: Props) => {
  const location = useLocation()
  const [open, setOpen] = useState<boolean>(Boolean(location.pathname.match(/^\/credits\/.+\/add/)))
  const { t } = useTranslation()

  if (supportedFinancingPrograms.length === 1) {
    return (
      <ListItemButton
        href={`/credits/${supportedFinancingPrograms[0]}/add`}
        selected={location.pathname === `/credits/${supportedFinancingPrograms[0]}/add`}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary={t('menus.addApplication')} />
      </ListItemButton>
    )
  }

  if (supportedFinancingPrograms.length > 1) {
    return (
      <>
        <ListItemButton onClick={() => setOpen((val) => !val)}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={t('menus.addApplication')} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {supportedFinancingPrograms.map((financingProgramId) => (
              <ListItemButton
                key={financingProgramId}
                sx={{ pl: 4 }}
                href={`/credits/${financingProgramId}/add`}
                selected={location.pathname === `/credits/${financingProgramId}/add`}
              >
                <ListItemText inset primary={t(`financingProgram.${financingProgramId}`)} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </>
    )
  }

  return null
})

export const AddApplicationButton = memo(({ supportedFinancingPrograms, ...props }: Props & ButtonProps) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const menuId = useId()
  const btnId = useId()

  if (supportedFinancingPrograms.length === 1) {
    return (
      <Button startIcon={<AddIcon />} href={`/credits/${supportedFinancingPrograms[0]}/add`} {...props}>
        {t('common.newApplication')}
      </Button>
    )
  }

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Button
        id={btnId}
        aria-controls={menuId}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<AddIcon />}
        {...props}
      >
        {t('menus.addApplication')}
      </Button>
      <Menu
        id={menuId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': btnId,
        }}
      >
        {supportedFinancingPrograms.map((e) => {
          const isAutoOrIfXpress = [EFinancingProgram.Auto, EFinancingProgram.IfXpress].includes(e)
          const currentUrl = window.location.href
          const baseUrl = (() => {
            if (currentUrl.includes('localhost') || currentUrl.includes('dev')) {
              return 'https://dev01.icebergfinance.ca'
            }
            if (currentUrl.includes('int') || currentUrl.includes('uat')) {
              return 'https://tests.icebergfinance.ca'
            }
            return 'https://secure.icebergfinance.ca'
          })()
          const url = isAutoOrIfXpress ? `${baseUrl}` : `/credits/${e}/add`

          return (
            <MenuItem key={e} component={Link} to={url} target={isAutoOrIfXpress ? '_blank' : undefined}>
              {t(`financingProgram.${e}`)}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
})
export const AddApplicationIconButton = memo(({ supportedFinancingPrograms, ...props }: Props & IconButtonProps) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const menuId = useId()
  const btnId = useId()

  if (supportedFinancingPrograms.length === 1) {
    return (
      <Tooltip title={t('menus.addApplication')}>
        <IconButton href={`/credits/${supportedFinancingPrograms[0]}/add`} {...props}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    )
  }

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Tooltip title={t('menus.addApplication')}>
        <IconButton
          id={btnId}
          aria-controls={menuId}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          {...props}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id={menuId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': btnId,
        }}
      >
        {supportedFinancingPrograms.map((e) => (
          <MenuItem key={e} component={Link} to={`/credits/${e}/add`}>
            {t(`financingProgram.${e}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
})
