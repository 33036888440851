import { FeatureSwitch } from '@src/types/Constants'

export type FeatureFlags =
  | 'UseIncludeInsuranceAllProvinces'
  | 'UseAllowWeekendOrHolidayActivationDate'
  | 'CreateMechanicalWarrantyApplication'

export const getFeatureFlags = (featureSwitch: FeatureSwitch[]) => {
  return featureSwitch.reduce(
    (acc, feature) => {
      acc[feature.id as FeatureFlags] = feature.isActivated
      return acc
    },
    {} as Record<FeatureFlags, boolean>,
  )
}
