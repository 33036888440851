import { Typography } from '@mui/material'
import { shouldDisplayInterest } from '@src/data/creditapp-selectors'
import { FormatCurrency } from '@src/services/Formatter'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { useTranslation } from 'react-i18next'
import { EDecision, FinancingProgramConfigs } from '@src/types/Constants'
import { usePaymentPlanList } from '../../worksheet-hooks'

type Props = {
  creditApp: CreditApplication
  merchant: Merchant
}

const CreditApproved = ({ creditApp, merchant }: Props) => {
  const { t } = useTranslation()
  const merchantPayments = usePaymentPlanList(creditApp, merchant)

  const getInterestRateOrPromo = () => {
    if (
      creditApp.worksheet?.finalDecision?.decision === EDecision.Accept &&
      creditApp.worksheet?.paymentPlanId !== null
    ) {
      const paymentPlan = merchantPayments.find((plan) => plan.id === creditApp.worksheet?.paymentPlanId)
      if (paymentPlan) {
        return paymentPlan.label
      }
    }

    return `${creditApp.finalCreditDecision.interestRate}%`
  }

  const mustDisplayInterests = shouldDisplayInterest(creditApp)

  const hasApplicantMismatch = creditApp.applicant.creditAlerts?.hasDoBMismatch
  const hasCoApplicantMismatch = creditApp.coApplicant?.creditAlerts?.hasDoBMismatch === true
  const displayMismatch =
    FinancingProgramConfigs[creditApp.financingProgramId].requiresCreditStepIntervention &&
    (hasApplicantMismatch || hasCoApplicantMismatch)

  return (
    <>
      {mustDisplayInterests && (
        <div>
          <Typography variant="subtitle1">{t('viewCreditApplication.interestRate')}</Typography>
          <Typography variant="body1">{getInterestRateOrPromo()}</Typography>
        </div>
      )}

      {FinancingProgramConfigs[creditApp.financingProgramId].useMaxPayment && (
        <div>
          <Typography variant="subtitle1">{t('viewCreditApplication.maxMontlyPayment')}</Typography>
          <Typography variant="body1">{FormatCurrency(creditApp.finalCreditDecision.maxPmtAmount)}</Typography>
        </div>
      )}

      <div>
        <Typography variant="subtitle1">{t('viewCreditApplication.amountGranted')}</Typography>
        <Typography variant="body1">{FormatCurrency(creditApp.finalCreditDecision.maxAmountFinanced)}</Typography>
      </div>

      {displayMismatch && (
        <Typography variant="body2">
          {hasApplicantMismatch && t('viewCreditApplication.applicant')}
          {hasApplicantMismatch && hasCoApplicantMismatch && ', '}
          {hasCoApplicantMismatch && t('viewCreditApplication.coApplicant')}
          {': '}
          {t('viewCreditApplication.dobMismatch')}
        </Typography>
      )}
    </>
  )
}

export default CreditApproved
