import { useMemo } from 'react'
import { ResourceDto } from '@src/types/ResourceDto'
import { EFinancingProgram } from '@src/types/Constants'
import resourcesData from '@src/data/programsResourcesFilesNames.json'

const getResources = (supportedFinancingPrograms: EFinancingProgram[], language: string): ResourceDto[] => {
  const data = resourcesData as { [key: string]: { [key: string]: string[] } }

  const resources: ResourceDto[] = []
  supportedFinancingPrograms.forEach((programId) => {
    const programFiles = data[programId]?.[language] || []
    programFiles.forEach((fileName) => {
      resources.push({
        id: `${programId}-${fileName}`,
        financingProgramId: programId,
        fileName,
        language,
      })
    })
  })

  return resources
}

const useResources = (supportedFinancingPrograms: EFinancingProgram[], language: string): [ResourceDto[], boolean] => {
  const resources = useMemo(
    () => getResources(supportedFinancingPrograms, language),
    [supportedFinancingPrograms, language],
  )

  return [resources, false]
}

export default useResources
