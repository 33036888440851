import type { TextFieldProps } from '@mui/material'
import { InputAdornment } from '@mui/material'
import { useMemo, useDeferredValue } from 'react'
import { useTranslation } from 'react-i18next'

const useDollarAdorment = () => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation()
  const defferedLanguage = useDeferredValue(resolvedLanguage)
  return useMemo<TextFieldProps['InputProps']>(() => {
    if (defferedLanguage === 'fr') {
      return {
        endAdornment: <InputAdornment position="end">$</InputAdornment>,
      }
    }
    return {
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    }
  }, [defferedLanguage])
}

export default useDollarAdorment
