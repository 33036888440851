import { Card, CardContent, Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { IconType } from '@src/types/Constants'
import IconSection from './iconSection'

type Props = {
  title: string
  subtitle?: React.ReactNode
  children: ReactNode
  iconMessage?: string | null
  iconType?: IconType | null
  onFooterClicked?: () => void
}

const StepStatusSection = ({ title, subtitle, children, iconType, iconMessage, onFooterClicked }: Props) => {
  return (
    <Card
      sx={{
        minWidth: '320px',
        maxWidth: '320px',
        minHeight: '40vh',
        borderRadius: 2,
        borderTop: 6,
        borderColor: 'secondary.main',
      }}
    >
      <Stack direction="column" sx={{ height: '100%' }}>
        <CardContent sx={{ flexGrow: 1 }} component={Stack} spacing={2}>
          <div>
            <Typography variant="h5">{title}</Typography>
            {subtitle && (
              <p style={{ margin: '0', fontSize: 'smaller', fontWeight: 'lighter', color: 'darkGray' }}>{subtitle}</p>
            )}
          </div>
          <Stack direction="column" justifyContent="space-around" sx={{ flexGrow: 1 }}>
            {children}
          </Stack>
        </CardContent>
        {iconMessage && <IconSection iconMessage={iconMessage} iconType={iconType} onClick={onFooterClicked} />}
      </Stack>
    </Card>
  )
}

export default StepStatusSection
