import { ResourceDto } from '@src/types/ResourceDto'

export const groupResourcesByProgram = (resources: ResourceDto[]): Record<string, ResourceDto[]> => {
  return resources.reduce(
    (acc, resource) => {
      if (!acc[resource.financingProgramId]) {
        acc[resource.financingProgramId] = []
      }
      acc[resource.financingProgramId].push(resource)
      return acc
    },
    {} as Record<string, ResourceDto[]>,
  )
}
export const filterProgramsWithResources = (resources: ResourceDto[]): Record<string, ResourceDto[]> => {
  const groupedResources = groupResourcesByProgram(resources)

  const programsWithResources = Object.fromEntries(
    Object.entries(groupedResources).filter(([_, programResources]) => programResources.length > 0),
  ) as Record<string, ResourceDto[]>

  return programsWithResources
}
