import { Address } from '@src/types/AddressSchema'
import {
  EFinancingProgram,
  EHomeStatus,
  EProvince,
  ERelationToApplicant,
  FinancingProgramConfigs,
} from '@src/types/Constants'
import { ApplicantJob, ApplicantOtherIncome, CreditApplication } from '@src/types/CreditApplicationSchema'
import { sumBy } from 'lodash-es'

function strCompare(param1: string | null | undefined, param2: string | null | undefined): boolean {
  return (param1 ?? '') === (param2 ?? '')
}

function DoesApplicantLiveWithCoapplicant(application: CreditApplication): boolean {
  const applicantAddress = application.applicant.currentAddress as Address
  const coApplicantAddress = application.coApplicant?.currentAddress as Address

  return (
    strCompare(applicantAddress.civicNumber, coApplicantAddress.civicNumber) &&
    strCompare(applicantAddress.apartment, coApplicantAddress.apartment) &&
    strCompare(applicantAddress.street, coApplicantAddress.street) &&
    strCompare(applicantAddress.city, coApplicantAddress.city) &&
    strCompare(applicantAddress.country, coApplicantAddress.country) &&
    strCompare(applicantAddress.stateIso, coApplicantAddress.stateIso) &&
    strCompare(applicantAddress.postalCode, coApplicantAddress.postalCode)
  )
}

export type BusinessError = {
  message: string
  params?: Record<string, string | number>
}

function isHousingExpenseValid(
  homeFeeTypeId: EHomeStatus | null | undefined,
  housingMonthly: number | null | undefined,
) {
  return (
    homeFeeTypeId === EHomeStatus.Owner ||
    (housingMonthly != null &&
      ((homeFeeTypeId === EHomeStatus.WithParents && housingMonthly >= 0) || housingMonthly > 0))
  )
}

export function validateCreditApplicationCanBeSubmittedForReview(application: CreditApplication): BusinessError[] {
  const ret: BusinessError[] = []

  const isProductAndNotQcApplicant =
    application.financingProgramId === EFinancingProgram.Products &&
    application.applicant.currentAddress.stateIso !== EProvince.quebec

  const hasCoapplicant = !!application?.coApplicant
  const applicantHousingExpensesValid =
    isHousingExpenseValid(
      application.applicant.expenses?.homeFeeTypeId,
      application.applicant.expenses?.housingMonthly,
    ) || isProductAndNotQcApplicant
  const coApplicantHousingExpensesValid =
    isHousingExpenseValid(
      application.coApplicant?.expenses?.homeFeeTypeId,
      application.coApplicant?.expenses?.housingMonthly,
    ) || isProductAndNotQcApplicant

  if (!isProductAndNotQcApplicant) {
    const applicantIncomes =
      Number(sumBy(application.applicant.currentJobs, (x: ApplicantJob) => x.annualSalary)) +
      Number(sumBy(application.applicant.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount))

    const coapplicantIncomes =
      Number(sumBy(application.coApplicant?.currentJobs, (x: ApplicantJob) => x.annualSalary)) +
      Number(sumBy(application.coApplicant?.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount))

    const programConfig = FinancingProgramConfigs[application.financingProgramId]
    const coapplicantIsSpouse =
      application.coApplicant?.relationWithApplicant === ERelationToApplicant.Spouse ||
      application.coApplicant?.relationWithApplicant === ERelationToApplicant.CommonLaw

    if (coapplicantIsSpouse && DoesApplicantLiveWithCoapplicant(application)) {
      if (
        (applicantIncomes < programConfig.minIncome || coapplicantIncomes < programConfig.minIncome) &&
        applicantIncomes + coapplicantIncomes < programConfig.minCombinedIncome
      )
        ret.push({
          message: 'editCreditApplication.errors.minIncomeNotMetCouple',
          params: { minIncome: programConfig.minIncome, minCombinedIncome: programConfig.minCombinedIncome },
        })
    } else if (
      applicantIncomes < programConfig.minIncome ||
      (hasCoapplicant && coapplicantIncomes < programConfig.minIncome)
    ) {
      ret.push({
        message: 'editCreditApplication.errors.minIncomeNotMet',
        params: { minIncome: programConfig.minIncome },
      })
    }
  }
  if (applicantHousingExpensesValid === false || (hasCoapplicant && coApplicantHousingExpensesValid === false)) {
    ret.push({ message: 'editCreditApplication.errors.expenses', params: {} })
  }
  return ret
}
