import { CardContent, CardHeader, MenuItem, Stack, Typography } from '@mui/material'
import { CardBorderTop } from '@src/components/common/Card'
import ControlledCheckbox from '@src/components/common/ControlledCheckbox'
import ControlledSelect from '@src/components/common/ControlledSelect'
import ControlledTextField from '@src/components/common/ControlledTextfield'
import useDollarAdorment from '@src/components/common/DollarAdorment'
import { ETerms } from '@src/types/Constants'
import { MechanicalWarrantyWorksheet } from '@src/types/WorksheetSchema'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const MechanicalWarrantyExtended = () => {
  const { control } = useFormContext<MechanicalWarrantyWorksheet>()
  const { t } = useTranslation()
  const dollarAdorment = useDollarAdorment()
  const termList = useMemo(
    () =>
      [ETerms.twelveMonths, ETerms.twentyFourMonths, ETerms.thirtySixMonths].map((term) => (
        <MenuItem key={term} value={term}>
          {term} {t('common.month')}
        </MenuItem>
      )),
    [t],
  )

  return (
    <CardBorderTop>
      <CardHeader title={t('mechanicalWarrantyWorksheet.extendedWarranty')} />
      <CardContent component={Stack} spacing={2}>
        <ControlledSelect
          label={t('mechanicalWarrantyWorksheet.providerLabel')}
          control={control}
          name="extendedWarranty.providerId"
          options={[]}
          required
        />
        <ControlledSelect
          label={t('mechanicalWarrantyWorksheet.productLabel')}
          control={control}
          name="extendedWarranty.productId"
          options={[]}
          required
        />
        <ControlledSelect
          label={t('worksheet.loanTerm')}
          name="extendedWarranty.term"
          control={control}
          options={termList}
          required
          fullWidth
        />
        <Stack>
          <Typography variant="subtitle2">{t('mechanicalWarrantyWorksheet.option')}</Typography>
          <ControlledCheckbox
            label={t('mechanicalWarrantyWorksheet.highTechnology')}
            control={control}
            name="extendedWarranty.isHighTechnology"
          />
          <Typography variant="subtitle2">{t('mechanicalWarrantyWorksheet.surcharge')}</Typography>
          <ControlledCheckbox
            label={t('mechanicalWarrantyWorksheet.electric')}
            control={control}
            name="extendedWarranty.isElectric"
          />
          <ControlledCheckbox
            label={t('mechanicalWarrantyWorksheet.luxury')}
            control={control}
            name="extendedWarranty.isLuxury"
          />
        </Stack>
        <ControlledTextField
          label={t('mechanicalWarrantyWorksheet.policy')}
          control={control}
          name="extendedWarranty.policyId"
          required
        />
        <ControlledTextField
          label={t('mechanicalWarrantyWorksheet.premium')}
          control={control}
          name="extendedWarranty.amount"
          type="number"
          InputProps={dollarAdorment}
          required
        />
      </CardContent>
    </CardBorderTop>
  )
}

export default MechanicalWarrantyExtended
