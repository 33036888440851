import { Divider, List, Stack, Typography } from '@mui/material'
import { ApplicantJob, ApplicantOtherIncome, JobSchema } from '@src/types/CreditApplicationSchema'
import { nanoid } from 'nanoid'
import React from 'react'
import { Control, FieldError, FieldErrorsImpl, Merge, UseFormRegister, useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AsyncActionButton from '@src/components/AsyncActionButton'
import AddIcon from '@mui/icons-material/Add'
import { EApplicantType, EFinancingProgram } from '@src/types/Constants'
import EditJobDialog from './EditJobDialog'
import OtherIncomeComponent from './OtherIncomeComponent'
import DisplayJobInfosListItem from './DisplayJobInfosListItem'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  errors: Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<ApplicantOtherIncome>> | undefined)[]> | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formControl: Control<any>
  applicantType: EApplicantType
  disableEdit: boolean
  toolTip: string
  financingProgramId: EFinancingProgram
}
const ApplicantIncomeSection = ({
  register,
  errors,
  applicantType: name,
  formControl,
  disableEdit,
  toolTip,
  financingProgramId,
}: Props) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [editingItemIndex, setEditingItemIndex] = React.useState<number | undefined>(undefined)
  const handleClose = (event?: object, reason?: string) => {
    if (reason !== 'backdropClick') {
      setEditingItemIndex(undefined)
      setOpen(false)
    }
  }

  const handleOpen = (itemIdex?: number) => {
    setEditingItemIndex(itemIdex)
    setOpen(true)
  }

  const { t } = useTranslation()

  const {
    fields: otherIncomesFields,
    append: otherIncomesAppend,
    remove: otherIncomesRemove,
  } = useFieldArray({
    name: `${name}.otherIncomes`,
    control: formControl,
  })

  const {
    append: currentJobsAppend,
    remove: currentJobsRemove,
    update: currentJobsUpdate,
  } = useFieldArray({ name: `${name}.currentJobs`, control: formControl })

  const currentJobsFields = useWatch({
    control: formControl,
    name: `${name}.currentJobs`,
  }) as ApplicantJob[]

  const addJobHandler = (data: ApplicantJob) => {
    currentJobsAppend(data)
    setOpen(false)
  }

  const updateJobHandler = (data: ApplicantJob, itemIndex: number) => {
    currentJobsUpdate(itemIndex, data)
    handleClose()
  }

  // Others Icomes
  const addOtherIncomesHandler = () => {
    otherIncomesAppend({ id: nanoid() } as ApplicantOtherIncome)
  }

  return (
    <>
      <Divider>
        <Typography variant="h5">{t('editCreditApplication.income.label')}</Typography>
      </Divider>

      <Stack direction="row" justifyContent="space-between" alignContent="baseline">
        <Typography variant="h6">{t('editCreditApplication.income.job.label')}</Typography>
        <AsyncActionButton
          fullWidth={false}
          id="addJob"
          onClick={() => handleOpen()}
          startIcon={<AddIcon />}
          disabled={disableEdit}
        >
          {t('editCreditApplication.income.job.addJob')}
        </AsyncActionButton>
      </Stack>

      {currentJobsFields && (
        <List style={{ marginTop: 0 }}>
          {currentJobsFields.map((item: ApplicantJob, index: number) => (
            <div key={item.id}>
              {index > 0 && <Divider component="li" />}
              <DisplayJobInfosListItem
                key={item.id}
                onDelete={() => currentJobsRemove(index)}
                onEdit={() => {
                  handleOpen(index)
                }}
                data={item}
                disableEdit={disableEdit || item.isIncomeLocked}
                toolTip={toolTip}
              />
            </div>
          ))}
        </List>
      )}
      <EditJobDialog
        defaultValue={
          editingItemIndex !== undefined
            ? currentJobsFields[editingItemIndex]
            : { ...(JobSchema.getDefault() as unknown as ApplicantJob), id: nanoid() }
        }
        onConfirm={(data: ApplicantJob) =>
          editingItemIndex !== undefined ? updateJobHandler(data, editingItemIndex) : addJobHandler(data)
        }
        onCancel={handleClose}
        open={open}
        title={
          editingItemIndex !== undefined
            ? t('editCreditApplication.income.job.editJob')
            : t('editCreditApplication.income.job.addJob')
        }
        financingProgramId={financingProgramId}
      />

      <Stack direction="row" justifyContent="space-between" alignContent="baseline" sx={{ mt: 2 }}>
        <Typography variant="h6">{t('editCreditApplication.income.other.label')}</Typography>
        <AsyncActionButton
          fullWidth={false}
          id="addOtherIncomes"
          startIcon={<AddIcon />}
          onClick={addOtherIncomesHandler}
          disabled={disableEdit}
        >
          {t('editCreditApplication.income.other.addOther')}
        </AsyncActionButton>
      </Stack>

      <Stack spacing={2}>
        {otherIncomesFields &&
          otherIncomesFields.map((item, index) => (
            <OtherIncomeComponent
              key={item.id}
              register={register}
              formControl={formControl}
              errors={errors?.[index]}
              name={`${name}.otherIncomes.${index}`}
              onDeleteClicked={() => otherIncomesRemove(index)}
              disableEdit={disableEdit}
              toolTip={toolTip}
            />
          ))}
      </Stack>
    </>
  )
}
export default ApplicantIncomeSection
