/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack } from '@mui/material'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { EServiceCategory } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  errors: FieldErrors<CreditApplication> | undefined
  loanPurposeId: EServiceCategory
  register: UseFormRegister<any>
  isGoodAndServiceLoanPurpose: boolean
  beneficiaryTypes: SelectValueListItem[]
  isVeterinaryLoanPurpose: boolean
  disableOtherName: boolean
}

const FinancingAmountAndBeneficiary = ({
  errors,
  loanPurposeId,
  register,
  isGoodAndServiceLoanPurpose,
  beneficiaryTypes,
  isVeterinaryLoanPurpose,
  disableOtherName,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Stack spacing={2}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 1 }}>
        <InputTextField
          id="loanPurposeId"
          value={loanPurposeId ? t(`serviceCategory.${loanPurposeId}`) : ''}
          disabled
          label={t('editCreditApplication.loanPurpose')}
          {...register('loanPurposeId')}
          error={errors?.loanPurposeId}
        />
        <InputTextField
          id="amountRequested"
          InputProps={{
            endAdornment: '$',
          }}
          error={errors?.requestedLoanAmount}
          label={t('editCreditApplication.amountRequested')}
          {...register('requestedLoanAmount')}
        />
      </Stack>
      {!isGoodAndServiceLoanPurpose && (
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 1 }}>
          <SelectComponent
            valueList={beneficiaryTypes}
            label={t('editCreditApplication.beneficiary.beneficiary') as string}
            disabled={isVeterinaryLoanPurpose}
            {...register('beneficiaryTypeId')}
            error={errors?.beneficiaryTypeId}
          />
          {!isVeterinaryLoanPurpose && (
            <InputTextField
              id="otherBeneficiaryFirstName"
              error={errors?.otherBeneficiaryFirstName}
              label={t('editCreditApplication.beneficiary.otherBeneficiaryFirstName')}
              disabled={disableOtherName}
              {...register('otherBeneficiaryFirstName')}
              inputProps={{ maxLength: 50 }}
            />
          )}
          <InputTextField
            id="otherBeneficiaryLastName"
            error={errors?.otherBeneficiaryLastName}
            label={
              !isVeterinaryLoanPurpose
                ? t('editCreditApplication.beneficiary.otherBeneficiaryLastName')
                : t('editCreditApplication.beneficiary.animalName')
            }
            disabled={disableOtherName}
            {...register('otherBeneficiaryLastName')}
            inputProps={{ maxLength: 50 }}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default FinancingAmountAndBeneficiary
