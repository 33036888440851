import { MutationFunction, useMutation, useQueryClient } from '@tanstack/react-query'
import { EContractStatus, EFinancingProgram } from '@src/types/Constants'
import { FilteredWorksheet } from '@src/types/FilteredWorksheet'
import { MechanicalWarrantyWorksheet } from '@src/types/WorksheetSchema'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { AxiosError } from 'axios'
import { getApiClient } from '../api-client'

const SCOPE = 'worksheet'
const CREDIT_APP_SCOPE = 'credit-applications'
const DETAIL = 'detail'
const keysFactory = {
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  creditAppDetail: (id: string, financingProgramId = EFinancingProgram.MechanicalWarranty) =>
    [{ scope: CREDIT_APP_SCOPE, entity: DETAIL, id, financingProgramId }] as const,
}

const createMechanicalWarrantyWorksheet: MutationFunction<
  FilteredWorksheet,
  { worksheet: MechanicalWarrantyWorksheet }
> = async ({ worksheet }) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<FilteredWorksheet>(
    `/Worksheet/${EFinancingProgram.MechanicalWarranty}/${encodeURIComponent(worksheet.creditApplicationId!)}`,
    worksheet,
  )

  return response.data
}

export function useCreateMechanicalWarrantyWorksheet(): [
  MutationFunction<FilteredWorksheet, { worksheet: MechanicalWarrantyWorksheet }>,
  boolean,
  boolean,
  AxiosError<{ message: string }> | null,
  () => void,
] {
  const queryClient = useQueryClient()
  const { isPending, isError, mutateAsync, error, reset } = useMutation({
    mutationFn: createMechanicalWarrantyWorksheet,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.creditAppDetail(data.creditApplicationId), (oldData: CreditApplication) => {
        return {
          ...oldData,
          worksheet: data,
          updatedOn: data.updatedOn,
          contract: { ...oldData.contract, status: EContractStatus.notReady },
        }
      })
    },
  })

  return [mutateAsync, isPending, isError, error as AxiosError<{ message: string }>, reset]
}

const editMechanicalWarrantyWorksheet: MutationFunction<
  FilteredWorksheet,
  { worksheet: MechanicalWarrantyWorksheet }
> = async ({ worksheet }) => {
  const apiClient = getApiClient()
  const response = await apiClient.put<FilteredWorksheet>(
    `/Worksheet/${EFinancingProgram.MechanicalWarranty}/${encodeURIComponent(worksheet.creditApplicationId!)}`,
    worksheet,
  )
  return response.data
}

export function useEditMechanicalWarrantyWorksheet(): [
  MutationFunction<FilteredWorksheet, { worksheet: MechanicalWarrantyWorksheet }>,
  boolean,
  boolean,
  AxiosError<{ message: string }> | null,
  () => void,
] {
  const queryClient = useQueryClient()
  const { isPending, isError, error, mutateAsync, reset } = useMutation({
    mutationFn: editMechanicalWarrantyWorksheet,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.creditAppDetail(data.creditApplicationId), (oldData: CreditApplication) => {
        return {
          ...oldData,
          worksheet: data,
          updatedOn: data.updatedOn,
          contract: { ...oldData.contract, status: EContractStatus.notReady },
        }
      })
    },
  })

  return [mutateAsync, isPending, isError, error as AxiosError<{ message: string }>, reset]
}
