import i18n from '@src/services/i18n'
import type { Address } from '@src/types/AddressSchema'

export function FormatCurrencyNoDecimals(data?: number | null | string | undefined) {
  let amount = 0
  if (data) {
    amount = parseFloat(data.toString())
  }
  const positifAmount = amount < 0 ? amount * -1 : amount

  let val = positifAmount?.toLocaleString(`${i18n.language}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  val = amount < 0 ? `(${val})` : val

  return val !== undefined && val !== null ? val : ''
}

export function FormatCurrencyNoDecimalsNoZero(data?: number | null | string | undefined) {
  let amount = 0
  if (data) {
    amount = parseFloat(data.toString())
  }
  const positifAmount = amount < 0 ? amount * -1 : amount
  let val = ''
  if (amount === 0) val = i18n.language === 'fr' ? '- $' : '$-'
  else
    val = positifAmount?.toLocaleString(`${i18n.language}-CA`, {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  val = amount < 0 ? `(${val})` : val

  return val !== undefined && val !== null ? val : ''
}

export function FormatCurrency(data?: number | string | null) {
  let amount = 0

  if (typeof data === 'number') {
    amount = data
  } else if (typeof data === 'string') {
    amount = parseFloat(data)
  }

  const positifAmount = amount < 0 ? amount * -1 : amount
  let val = positifAmount.toLocaleString(`${i18n.language}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  val = amount < 0 ? `(${val})` : val
  return val !== undefined && val !== null ? val : ''
}

export function FormatPercentage(data?: number | string | null) {
  let amount = 0

  if (data === null || data === undefined) {
    return '0.00'
  }

  if (typeof data === 'number') {
    amount = data
  } else if (typeof data === 'string') {
    amount = parseFloat(data)
  }

  return `${amount.toFixed(2)}`
}

export function formatNumber(data?: number | null | undefined) {
  const val = data?.toLocaleString(`${i18n.language}-CA`, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
  return val !== undefined && val !== null ? val : ''
}

/**
 *
 * @param data - string representation of the number to be formatted
 * @returns - formatted number or null
 */
export function normalizeNumber(data?: string | null | undefined): number | null {
  if (!data) return null
  const val = data.toString()
  const parsed = Math.trunc(Number(val.replace(',', '.').replace(' ', '').replace('$', '')) * 100) / 100
  return parsed !== undefined && parsed !== null ? parsed : null
}

export const formatDateTime = (date: Date | null | undefined) =>
  !date ? '' : new Date(date).toLocaleString('en-CA', { hour12: true })

export const formatDate = (date: Date | null | string | undefined): string => {
  if (!date) {
    return ''
  }

  if (typeof date === 'string') {
    const dateString = date.split('T')[0]
    if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return dateString
    }
    // eslint-disable-next-line no-param-reassign
    date = new Date(date)
  }

  if (date instanceof Date) {
    const year = date.getUTCFullYear()
    const month = date.getUTCMonth() + 1
    const day = date.getUTCDate()
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
  }

  return ''
}

export const formatIsoDate = (date: Date) => {
  const d = new Date(date)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return [year, month, day].join('-')
}

export const formatUtcIsoDateTime = (date: Date) => {
  return date.toISOString()
}
export const formatTime = (date: Date | null | string | undefined) => {
  if (!date) return ''
  let dateString = date.toString()

  if (dateString.split('T') && dateString.split('T').length === 1) dateString = dateString.concat('T00:00:00')
  if (Date.parse(dateString)) return new Date(dateString).toLocaleTimeString('en-CA')

  return date
}

export const convertToLocalDate = (date: string) => {
  return new Date(date.replace(/-/g, '/'))
}

export const formatPhone = (phoneNumber: string | undefined | null): string => {
  if (!phoneNumber) return 'N/A'
  const phoneNumberClean = phoneNumber.replace(/-/g, '')
  return `${phoneNumberClean.slice(0, 3)}-${phoneNumberClean.slice(3, 6)}-${phoneNumberClean.slice(6)}`
}

export const formatAddress = (address: Address | null | undefined) => {
  if (!address) return 'N/A'
  let ret = ''
  if (address.apartment) ret = ret.concat(`${address.apartment}-`)
  if (address.civicNumber) ret = ret.concat(address.civicNumber)
  if (address.street) ret = ret.concat(` ${address.street}`)
  if (address.city) ret = ret.concat(`, ${address.city}`)
  if (address.stateIso) ret = ret.concat(`, ${address.stateIso}`)
  if (address.country) ret = ret.concat(`, ${address.country}`)
  if (address.postalCode) ret = ret.concat(`, ${address.postalCode?.toUpperCase()}`)
  return ret
}
