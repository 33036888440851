import { MenuItem } from '@mui/material'
import { computeFirstPaymentDateOptions, getFormattedPlan } from '@src/data/worksheet-selectors'
import { formatUtcIsoDateTime } from '@src/services/Formatter'
import { EFrequencyType, EPaymentPlan, FinancingProgramConfig, MerchantId, PaymentMethod } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { UserDtoFromJwt } from '@src/types/User'
import { isAfter, isWithinInterval, addMonths, isValid, addWeeks, parse } from 'date-fns'
import React, { useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const useComputeFirstPaymentDateOptions = (activationDate: string, listHolidays: Date[]) => {
  return useMemo(() => {
    return computeFirstPaymentDateOptions(activationDate, listHolidays)
  }, [activationDate, listHolidays])
}

export const usePaymentPlanList = (creditApp: CreditApplication, merchant: Merchant) => {
  return useMemo(() => {
    const selectValueList: { label: string; value: string; id: string }[] = []

    selectValueList.push({
      value: EPaymentPlan.regularDailyInterests,
      label: EPaymentPlan.regularDailyInterests,
      id: merchant?.id,
    })

    const currentDate = new Date()
    const creationDate = new Date(creditApp?.createdOn)
    const DISTANT_FUTURE = new Date()
    DISTANT_FUTURE.setFullYear(DISTANT_FUTURE.getFullYear() + 100)

    const filteredPaymentPlans = merchant?.paymentPlans?.filter((plan) => {
      const planActiveFrom = new Date(plan.activeFrom)
      const planExpiresOn = new Date(plan?.expiresOn ?? DISTANT_FUTURE)

      const currentUtcDateFormatted = new Date(formatUtcIsoDateTime(new Date()))
      return (
        (isWithinInterval(creationDate, { start: planActiveFrom, end: planExpiresOn }) ||
          isWithinInterval(currentDate, { start: planActiveFrom, end: planExpiresOn })) &&
        isAfter(planExpiresOn, currentUtcDateFormatted)
      )
    })

    filteredPaymentPlans?.forEach((p) => {
      selectValueList.push({ value: p.id, label: `${p.promoCode} ${getFormattedPlan(p)}`, id: p.id })
    })

    return selectValueList
  }, [creditApp?.createdOn, merchant?.id, merchant?.paymentPlans])
}

export const usePaymentMerchantList = (
  creditApplication: CreditApplication,
  merchantsListFromParentId: Merchant[],
  merchant: Merchant | null,
  user: UserDtoFromJwt,
) => {
  const [merchantList, setMerchantList] = React.useState<Merchant[]>([])

  useEffect(() => {
    let newMerchantList: Merchant[] = []
    if (merchant?.forcePaymentToBorrower === true) {
      newMerchantList = [
        {
          id: MerchantId.PayCustomerByBankTransfer,
          defaultPaymentMethod: PaymentMethod.bankTransfer,
          name: `${creditApplication.applicant.firstName} ${creditApplication.applicant.lastName}`,
        } as unknown as Merchant,
      ]
    } else if (creditApplication?.worksheet && user.isBannerUser) {
      const merchantIds = creditApplication.worksheet.merchantPayments.map((payment) => payment.merchantId)
      newMerchantList = merchantsListFromParentId.filter((m) => merchantIds.includes(m.id))
    } else if (merchant) {
      newMerchantList = [merchant]
    }
    return setMerchantList(newMerchantList)
  }, [creditApplication, merchant, merchantsListFromParentId, user.isBannerUser])
  return { merchantList, setMerchantList }
}

export const useProductsEarliestPaymentDate = () => {
  const getProductsEarliestPaymentDateGivenActivationDate = useCallback((activationDate: Date) => {
    const initialEarliestDateForFirstPayment = new Date(activationDate)
    initialEarliestDateForFirstPayment.setDate(initialEarliestDateForFirstPayment.getDate())
    return initialEarliestDateForFirstPayment
  }, [])

  return getProductsEarliestPaymentDateGivenActivationDate
}

export const useProductsFirstPaymentDateOptions = () => {
  const getProductsEarliestPaymentDateGivenActivationDate = useProductsEarliestPaymentDate()

  const computeProductsFirstPaymentDateOptions = useCallback(
    (activationDate: string, frequency: EFrequencyType) => {
      const activationDateAsDate = parse(activationDate, 'yyyy-MM-dd', new Date())
      const earliestPaymentDate = getProductsEarliestPaymentDateGivenActivationDate(activationDateAsDate)

      const firstPaymentDateOptionsList: Array<Date> = []
      const currentDate = new Date(earliestPaymentDate)
      const oneMonthLaterOrTwoWeeksLater =
        frequency === EFrequencyType.Monthly ? addMonths(earliestPaymentDate, 1) : addWeeks(earliestPaymentDate, 2)

      while (currentDate <= oneMonthLaterOrTwoWeeksLater) {
        if (isValid(currentDate)) {
          firstPaymentDateOptionsList.push(new Date(currentDate))
        }
        currentDate.setDate(currentDate.getDate() + 1)
      }

      return firstPaymentDateOptionsList
    },
    [getProductsEarliestPaymentDateGivenActivationDate],
  )

  return computeProductsFirstPaymentDateOptions
}

export const useTermOptionList = (config: FinancingProgramConfig) => {
  const { t } = useTranslation()
  return useMemo(
    () =>
      config.termList.map((term) => (
        <MenuItem key={term} value={term}>
          {term} {t('common.month')}
        </MenuItem>
      )),
    [config.termList, t],
  )
}
