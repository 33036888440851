import { Box, Typography } from '@mui/material'
import { FundingSummaryDto } from '@src/data/api/credit-api/credit-api'
import { FormatCurrency, formatPhone } from '@src/services/Formatter'
import { useTranslation } from 'react-i18next'

interface Props {
  fundingSummary: FundingSummaryDto
}

const IFinanceMerchantInformation = ({ fundingSummary }: Props) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ marginLeft: '15%' }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'primary' }}>
        {t('fundingSummary.serviceProviderInformation')}
      </Typography>
      <Box sx={{ marginLeft: '2.5%' }}>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.internalAccountNumber')}</strong> {fundingSummary?.merchantId}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.paymentMethod')}</strong> {fundingSummary?.merchantPaymentMethod}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.serviceProvider')}</strong> {fundingSummary?.merchantName}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.address')}</strong> {fundingSummary?.merchantAddress}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.phone')}</strong> {formatPhone(fundingSummary?.merchantPhone)}
        </Typography>
        {fundingSummary?.promotion && (
          <Typography variant="subtitle1" gutterBottom>
            <strong>{t('fundingSummary.promotion')}</strong> {fundingSummary?.promotion}
          </Typography>
        )}
        {fundingSummary?.merchantFee && (
          <Typography variant="subtitle1" gutterBottom>
            <strong>{t('fundingSummary.retainedFee')}</strong> {FormatCurrency(fundingSummary?.merchantFee)}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default IFinanceMerchantInformation
