import { Card, styled } from '@mui/material'

export type ColorType = 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'

export const CardBorderTop = styled(Card)<{ color?: ColorType }>(({ theme, color }) => ({
  borderTop: `6px solid ${theme.palette[color ?? 'primary'].main}`,
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
  borderColor: theme.palette.secondary.main,
}))
