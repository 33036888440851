import { Box, Typography } from '@mui/material'
import { FundingSummaryDto } from '@src/data/api/credit-api/credit-api'
import { useTranslation } from 'react-i18next'

interface Props {
  fundingSummary: FundingSummaryDto
}

const IcebergMerchantInformation = ({ fundingSummary }: Props) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ marginLeft: '15%' }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'primary' }}>
        {t('fundingSummary.merchantInformation')}
      </Typography>
      <Box sx={{ marginLeft: '2.5%' }}>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.paymentMethod')}</strong> {t('fundingSummary.directDeposit')}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('common.merchant')}</strong>: {fundingSummary?.merchantName}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.address')}</strong> {fundingSummary?.merchantAddress}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.phone')}</strong> {fundingSummary?.merchantPhone}
        </Typography>
      </Box>
    </Box>
  )
}

export default IcebergMerchantInformation
