import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Info as InfoIcon } from '@mui/icons-material'
import { ECreditApplicationStatus, ECurrentCustomerStep, EDecision, EOriginSystemId } from '@src/types/Constants'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { useMemo } from 'react'
import {
  getContractColor,
  getCreditColor,
  getFundingColor,
  getIncomeVerificationColor,
  getPrequalColor,
} from './CreditApplicationProgressUtils'

type Props = {
  status: string
  prequalDecision: EDecision
  hardHitDecision: EDecision
  incomeConfirmed: boolean
  isContractSignatureMethodSelected: boolean
  creditApplicationStatus: ECreditApplicationStatus
  currentCustomerStep: ECurrentCustomerStep
  missingRequiredDocuments: RequiredDocument[]
  originSystemId: EOriginSystemId
  canSkipFlinks: boolean
  displayPrequalify: boolean
  displayIncomeVerification: boolean
}

const CreditApplicationProgressStatusBar = ({
  status,
  prequalDecision,
  hardHitDecision,
  incomeConfirmed,
  isContractSignatureMethodSelected,
  creditApplicationStatus,
  currentCustomerStep,
  missingRequiredDocuments,
  originSystemId,
  canSkipFlinks,
  displayPrequalify,
  displayIncomeVerification,
}: Props) => {
  const { t } = useTranslation()

  const getToolTip = useMemo<React.ReactNode>(() => {
    if (currentCustomerStep === ECurrentCustomerStep.IncomeVerification && canSkipFlinks) {
      return `${t(`dashboardEntriesStatus.tooltips.${currentCustomerStep}`)} ${t(
        'dashboardEntriesStatus.tooltips.incomeVerificationOptions',
      )}`
    }
    return t(`dashboardEntriesStatus.tooltips.${currentCustomerStep}`)
  }, [canSkipFlinks, currentCustomerStep, t])

  return (
    <Stack direction="column">
      <Stack direction="row" alignItems="center" minHeight={25}>
        <Typography variant="inherit" lineHeight="normal">
          {t(`dashboardEntriesStatus.${currentCustomerStep}`)}
        </Typography>
        {currentCustomerStep !== ECurrentCustomerStep.Draft && originSystemId === EOriginSystemId.MerchantDashboard && (
          <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{getToolTip}</span>}>
            <IconButton size="small">
              <InfoIcon sx={{ fontSize: 15 }} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack direction="row" spacing={0.5}>
        {displayPrequalify && (
          <Box
            sx={{
              height: '0.2rem',
              width: '1rem',
              backgroundColor: getPrequalColor(creditApplicationStatus, prequalDecision),
            }}
          />
        )}

        <Box
          sx={{ height: '0.2rem', width: '1rem', backgroundColor: getCreditColor(prequalDecision, hardHitDecision) }}
        />

        {displayIncomeVerification && (
          <Box
            sx={{
              height: '0.2rem',
              width: '1rem',
              backgroundColor: getIncomeVerificationColor(prequalDecision, hardHitDecision, incomeConfirmed),
            }}
          />
        )}

        <Box
          sx={{
            height: '0.2rem',
            width: '1rem',
            backgroundColor: getContractColor(
              hardHitDecision,
              incomeConfirmed,
              isContractSignatureMethodSelected,
              missingRequiredDocuments,
            ),
          }}
        />
        <Box
          sx={{
            height: '0.2rem',
            width: '1rem',
            backgroundColor: getFundingColor(
              status,
              hardHitDecision,
              incomeConfirmed,
              isContractSignatureMethodSelected,
            ),
          }}
        />
      </Stack>
    </Stack>
  )
}

export default CreditApplicationProgressStatusBar
