import type { TextFieldProps } from '@mui/material'
import { type PickerValidDate, type DatePickerProps, DatePicker } from '@mui/x-date-pickers'
import type { Control, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'

type Props<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>, TDate extends PickerValidDate> = {
  control: Control<TFieldValues>
  name: TName
  required?: boolean
  fullWidth?: boolean
  disabled?: boolean
} & Omit<DatePickerProps<TDate>, keyof ControllerRenderProps<TFieldValues, TName>>

const ControlledDatePicker = <
  TDate extends PickerValidDate,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  slotProps,
  required,
  fullWidth,
  disabled: pDisabled,
  ...props
}: Props<TFieldValues, TName, TDate>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { disabled, ...field }, fieldState: { invalid, error } }) => (
        <DatePicker
          {...props}
          {...field}
          disabled={pDisabled || disabled}
          slotProps={{
            ...slotProps,
            field: {
              ...slotProps?.field,
              clearable: !required,
            },
            textField: {
              ...slotProps?.textField,
              error: invalid,
              required,
              fullWidth,
              inputProps: {
                ...(slotProps?.textField as TextFieldProps)?.inputProps,
                formNoValidate: true,
              },
              helperText: error?.message ?? (slotProps?.textField as TextFieldProps)?.helperText,
            },
          }}
        />
      )}
    />
  )
}

export default ControlledDatePicker
