import { Checkbox, FormControlLabel, FormControlLabelProps } from '@mui/material'
import { Control, Controller, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'

export type ControlledCheckboxProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = {
  control: Control<TFieldValues>
  name: TName
  disabled?: boolean
} & Omit<FormControlLabelProps, keyof ControllerRenderProps<TFieldValues, TName> | 'control'>

const ControlledCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  disabled: pDisabled,
  ...props
}: ControlledCheckboxProps<TFieldValues, TName>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, ref, disabled, ...field } }) => (
        <FormControlLabel
          {...props}
          {...field}
          disabled={pDisabled || disabled}
          onChange={(_, checked) => onChange(checked)}
          inputRef={ref}
          checked={value}
          control={<Checkbox inputProps={{ formNoValidate: true }} />}
        />
      )}
    />
  )
}

export default ControlledCheckbox
