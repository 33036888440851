import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { cvtAwaitingApproval } from '@src/data/creditapp-selectors'
import { EOriginSystemId } from '@src/types/Constants'
import { ButtonGroup, Stack } from '@mui/material'
import SignManuallyButton from './SignManuallyButton'
import CreateContractButton from './CreateContractButton'
import SignDigitallyButton from './SignDigitallyButton'
import ContractInformation from './ContractInformation'

type Props = {
  creditApp: CreditApplication
}
const ContractAwaitingSignature = ({ creditApp }: Props) => {
  return (
    <>
      <ContractInformation creditApp={creditApp} />
      {creditApp.originSystemId === EOriginSystemId.MerchantDashboard && (
        <Stack spacing={1}>
          {!cvtAwaitingApproval(creditApp.requiredDocuments) && <CreateContractButton creditApp={creditApp} />}

          <ButtonGroup size="small">
            <SignDigitallyButton creditApp={creditApp} />
            <SignManuallyButton creditApp={creditApp} />
          </ButtonGroup>
        </Stack>
      )}
    </>
  )
}

export default ContractAwaitingSignature
