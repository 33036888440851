import { useFeatureSwitchList } from '@src/data/api/credit-api/credit-api'
import { getFeatureFlags } from '@src/data/config-selectors'
import { useMemo } from 'react'

const useFeatureFlags = () => {
  const [featureSwitchInfo, isFetchingFeatureSwitch] = useFeatureSwitchList()

  const flags = useMemo(() => {
    return getFeatureFlags(featureSwitchInfo?.featureSwitches || [])
  }, [featureSwitchInfo])

  return { flags, isFetchingFeatureSwitch }
}

export default useFeatureFlags
