import { CreditApplication } from '@src/types/CreditApplicationSchema'
import {
  availableCreditEqualApprovedAmount,
  areAllRequiredDocumentForStepApproved,
} from '@src/data/creditapp-selectors'
import {
  EContractStatus,
  ECreditApplicationStatus,
  EDecision,
  EWorksheetStatus,
  ECreditApplicationStep,
} from '@src/types/Constants'
import { CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import StepStatusSection from '../../../App/components/StepStatusSection'
import { GetContractIconAndMessage } from '../../viewCreditApp-selectors'
import ContractCompleted from './ContractCompleted'
import ContractAwaitingSignature from './ContractAwaitingSignature'
import CreateContractButton from './CreateContractButton'

type Props = {
  creditApp: CreditApplication
  isPolling: boolean
  canEdit: boolean
}

const ContractTile = ({ creditApp, isPolling, canEdit }: Props) => {
  const { t } = useTranslation()
  const icon = GetContractIconAndMessage(creditApp, creditApp.worksheet)

  const allDocumentsRequiredForCvtApproved = areAllRequiredDocumentForStepApproved(
    creditApp,
    ECreditApplicationStep.CVT,
  )
  const isWorksheetApproved = creditApp.worksheet?.finalDecision.decision === EDecision.Accept
  const contractTileTitle =
    isWorksheetApproved &&
    (creditApp.contract.status === EContractStatus.completed ||
      creditApp.contract.status === EContractStatus.ready ||
      creditApp.contract.status === EContractStatus.sending)
      ? `${t('worksheet.contractNumber')} #${creditApp.contract.cvtNumber}`
      : t('viewCreditApplication.contractSectionTitle')
  if (creditApp.finalCreditDecision.decision !== EDecision.Accept) {
    return (
      <StepStatusSection title={t('viewCreditApplication.contractSectionTitle')}>
        <Typography variant="body1">{t('viewCreditApplication.creditNotApproved')}</Typography>{' '}
      </StepStatusSection>
    )
  }

  if (!creditApp.allIncomesConfirmed) {
    // Income Not Completed
    return (
      <StepStatusSection title={t('viewCreditApplication.contractSectionTitle')}>
        <Typography variant="body1">{t('viewCreditApplication.incomeNotCompleted')}</Typography>{' '}
      </StepStatusSection>
    )
  }

  if (creditApp.hasTasksBeforeCvt && !allDocumentsRequiredForCvtApproved) {
    // Cvt Tasks Not Completed
    return (
      <StepStatusSection
        title={t('viewCreditApplication.contractSectionTitle')}
        iconMessage={icon.iconMessage ? t(`creditApplicationStatus.${icon.iconMessage}`) : null}
        iconType={icon.iconType}
      >
        <Typography variant="body1">{t('viewCreditApplication.cvtTasksNotCompleted')}</Typography>
      </StepStatusSection>
    )
  }

  if (!allDocumentsRequiredForCvtApproved) {
    return (
      <StepStatusSection
        title={contractTileTitle}
        iconMessage={icon.iconMessage ? t(`creditApplicationStatus.${icon.iconMessage}`) : null}
        iconType={icon.iconType}
      >
        <>
          {icon.iconMessage === ECreditApplicationStatus.DocumentsRequired && (
            <Typography variant="body1">{t('viewCreditApplication.requiredDocuments')}</Typography>
          )}
          {icon.iconMessage === ECreditApplicationStatus.AwaitingApproval && (
            <Typography variant="body1">{t('viewCreditApplication.documentsAwaitingApproval')}</Typography>
          )}
        </>
      </StepStatusSection>
    )
  }

  const isAvailableCreditEqualApprovaleAmount = availableCreditEqualApprovedAmount(creditApp)
  const showCreateComponent =
    (creditApp.worksheet == null ||
      creditApp.worksheet.status === EWorksheetStatus.Draft ||
      creditApp.worksheet.finalDecision.decision === EDecision.ManualRevision) &&
    canEdit

  return (
    <StepStatusSection
      title={contractTileTitle}
      iconMessage={icon.iconMessage ? t(`creditApplicationStatus.${icon.iconMessage}`) : null}
      iconType={icon.iconType}
    >
      {icon.iconMessage === ECreditApplicationStatus.Cancelled && (
        <Typography variant="body1">{t('viewCreditApplication.creditAppCancelled')}</Typography>
      )}

      {!isAvailableCreditEqualApprovaleAmount && showCreateComponent && (
        <Typography variant="body1">{t('viewCreditApplication.availableCreditNotCorresponding')}</Typography>
      )}

      {!showCreateComponent &&
        (creditApp.worksheet == null || !isWorksheetApproved) &&
        creditApp.status !== ECreditApplicationStatus.Cancelled && (
          <Typography>{t('worksheet.contractNotCreated')}</Typography>
        )}

      {isAvailableCreditEqualApprovaleAmount &&
        showCreateComponent &&
        creditApp.status !== ECreditApplicationStatus.Cancelled && <CreateContractButton creditApp={creditApp} />}

      {isWorksheetApproved && creditApp.contract.status === EContractStatus.completed && (
        <ContractCompleted creditApp={creditApp} />
      )}
      {isWorksheetApproved &&
        (creditApp.contract.status === EContractStatus.ready ||
          creditApp.contract.status === EContractStatus.sending) && <ContractAwaitingSignature creditApp={creditApp} />}
      {isWorksheetApproved && creditApp.contract.status === EContractStatus.notReady && isPolling && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {isWorksheetApproved && creditApp.contract.status === EContractStatus.notReady && !isPolling && (
        <div>
          <Typography> {t('worksheet.error')}</Typography>
        </div>
      )}
    </StepStatusSection>
  )
}

export default ContractTile
