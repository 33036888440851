import { Paper, Tab, Tabs, Toolbar } from '@mui/material'
import Box from '@mui/material/Box'
import {
  DataGrid,
  FooterPropsOverrides,
  GridColDef,
  GridPaginationModel,
  GridSlotProps,
  useGridApiRef,
} from '@mui/x-data-grid'
import { useUser } from '@src/contexts/AppContext'
import { useCreditApplicationList } from '@src/data/api/credit-api/credit-api'
import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import { getNumberFromQueryString } from '@src/services/query-string'
import {
  ECreditApplicationStatus,
  EFinancingProgram,
  EServiceCategory,
  HiddenMerchantCategories,
} from '@src/types/Constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { frFR, enUS } from '@mui/x-data-grid/locales'
import { CreditDashboardFilters } from '@src/data/api/type'
import { AddApplicationButton, AddApplicationIconButton } from '@src/components/AddApplication'
import useFeatureFlags from '@src/hooks/config-hooks'
import { useCreditDashboardEntriesColumns, useCreditDashboardEntriesQuery } from './browseCreditApplication-hooks'
import CreditEntriesFiltersDialog from './components/CreditEntriesFiltersDialog'
import TableFilters from './components/TableFilters'
import Footer from './components/footer'
import { getEndDate, getStartDate } from './browseCreditApplicationPage-selector'

const BrowseCreditApplicationPage = () => {
  const {
    i18n: { resolvedLanguage },
    t,
  } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const updateSearch = new URLSearchParams(location.search)
  const user = useUser()
  const apiRef = useGridApiRef()
  const { flags } = useFeatureFlags()

  const query = useCreditDashboardEntriesQuery(searchParams)

  const [creditApplications, isLoadingCreditApps] = useCreditApplicationList(query)
  const [merchant] = useMerchantById(user?.merchantId ?? '')

  const merchantServiceCategory = merchant?.serviceCategory
  const isVeterinary = merchantServiceCategory === EServiceCategory.Veterinary
  const isHidden = merchantServiceCategory ? HiddenMerchantCategories.includes(merchantServiceCategory) : false
  const onlyProductsApplications = creditApplications.every(
    (app) => app.financingProgramId === EFinancingProgram.Products,
  )

  // update search
  const [openFilter, setOpenFilter] = React.useState(false)

  const updateUriQuery = () => {
    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: true })
  }

  const handleSearch = (value: string) => {
    updateSearch.set('searchValue', encodeURIComponent(value))
    updateUriQuery()
  }

  const handleTabChange = (status: string) => {
    updateSearch.set('creditApplicationStatus', encodeURIComponent(status))
    updateUriQuery()
  }

  const handleFilters = (filters: CreditDashboardFilters) => {
    updateSearch.set('merchantId', encodeURIComponent(filters.merchantId))
    updateSearch.set('creditApplicationStatus', encodeURIComponent(filters.creditApplicationStatus))
    updateSearch.set('currentCustomerStep', encodeURIComponent(filters.currentCustomerStep))
    updateSearch.set('requiresMerchantAttention', encodeURIComponent(filters.requiresMerchantAttention))
    updateSearch.set('startDate', encodeURIComponent(filters.startDate))
    updateSearch.set('endDate', encodeURIComponent(filters.endDate))
    updateSearch.set('originSystemId', encodeURIComponent(filters.originSystemId))
    updateSearch.set('createdByUserId', encodeURIComponent(filters.createdByUserId))

    updateUriQuery()
    setOpenFilter(false)
  }

  const setPagination = (pagination: GridPaginationModel) => {
    updateSearch.set('offset', encodeURIComponent(query.limit * pagination.page))
    updateSearch.set('limit', encodeURIComponent(pagination.pageSize))
    updateUriQuery()
  }

  const pagination = {
    pageSize: getNumberFromQueryString(searchParams, 'limit', 25),
    page: getNumberFromQueryString(searchParams, 'offset', 0) / getNumberFromQueryString(searchParams, 'limit', 25),
  }

  // Table def
  const columns: GridColDef[] = useCreditDashboardEntriesColumns(isVeterinary, merchant?.canSkipFlinks ?? false)

  const supportedFinancingPrograms = !flags.CreateMechanicalWarrantyApplication
    ? merchant?.supportedFinancingPrograms.filter((e) => e !== EFinancingProgram.MechanicalWarranty)
    : merchant?.supportedFinancingPrograms

  const localeTextDatagrid =
    resolvedLanguage === 'fr'
      ? frFR.components.MuiDataGrid.defaultProps.localeText
      : enUS.components.MuiDataGrid.defaultProps.localeText

  const handleClearFilter = () => {
    handleFilters({
      merchantId: '',
      creditApplicationStatus: ECreditApplicationStatus.Active,
      currentCustomerStep: '',
      startDate: getStartDate(),
      endDate: getEndDate(),
      originSystemId: '',
      createdByUserId: '',
    } as CreditDashboardFilters)
  }

  const hasAFilter = (): boolean => {
    return (
      ![ECreditApplicationStatus.Active, ECreditApplicationStatus.Draft].includes(query.creditApplicationStatus) ||
      query.merchantId !== '' ||
      query.currentCustomerStep !== '' ||
      query.requiresMerchantAttention ||
      query.startDate !== getStartDate() ||
      query.endDate !== getEndDate() ||
      query.originSystemId !== '' ||
      query.createdByUserId !== ''
    )
  }

  return (
    <>
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <TableFilters
            handleSearch={handleSearch}
            onOpenFilterClicked={() => setOpenFilter(true)}
            creditApplicationStatusFilter={query.creditApplicationStatus}
            handleClearFilter={handleClearFilter}
            hasAFilter={hasAFilter()}
          />
          {merchant && (
            <>
              <AddApplicationButton
                sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                supportedFinancingPrograms={supportedFinancingPrograms ?? []}
                variant="contained"
                color="primary"
              />
              <AddApplicationIconButton
                sx={{ display: { md: 'none' } }}
                supportedFinancingPrograms={supportedFinancingPrograms ?? []}
                color="primary"
              />
            </>
          )}
        </Toolbar>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={
              query.creditApplicationStatus === ECreditApplicationStatus.Active ||
              query.creditApplicationStatus === ECreditApplicationStatus.Draft
                ? query.creditApplicationStatus
                : false
            }
            onChange={(e, newStatus: string) => handleTabChange(newStatus)}
          >
            <Tab label={t('common.applications')} value={ECreditApplicationStatus.Active} />
            <Tab label={t('common.drafts')} value={ECreditApplicationStatus.Draft} />
          </Tabs>
        </Box>
        <DataGrid
          autoHeight
          sx={{
            '.MuiDataGrid-cell': {
              fontWeight: 'bold',
            },
          }}
          rows={creditApplications}
          columns={columns}
          apiRef={apiRef}
          columnVisibilityModel={{
            interestRate: !isHidden,
            merchantName: user?.isBannerUser ?? false,
            maxAmountFinanced: !onlyProductsApplications,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: query.limit,
              },
            },
          }}
          disableVirtualization
          onPaginationModelChange={(model: GridPaginationModel) => setPagination(model)}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
          rowCount={creditApplications.length}
          loading={isLoadingCreditApps}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          localeText={localeTextDatagrid}
          slots={{
            footer: Footer as React.JSXElementConstructor<GridSlotProps['footer']>,
          }}
          slotProps={{
            footer: {
              disabledPreview: query.offset === 0,
              disabledNext: creditApplications.length < query.limit,
              pagination,
              setPagination,
            } as FooterPropsOverrides,
          }}
        />
      </Paper>
      <CreditEntriesFiltersDialog
        open={openFilter}
        merchant={merchant}
        filterValues={query}
        onApplyFilters={handleFilters}
        onCancel={() => setOpenFilter(false)}
        handleClearFilter={handleClearFilter}
      />
    </>
  )
}

export default BrowseCreditApplicationPage
